import React, { useState, useEffect } from 'react';
import { Box as MuiBox } from '@mui/material';
import { styled } from '@mui/system';
import { useFormData } from '../../provider/FormDataProvider'; // Assurez-vous que le chemin d'accès est correct

function BI_6({ setSaveCurrentFormData }) {
  const { formData, updateFormData } = useFormData();
  const [selectedOption, setSelectedOption] = useState(formData.bi6Option || '');

  const handleButtonClick = (option) => {
    setSelectedOption(prevOption => prevOption === option ? '' : option);
  };

  useEffect(() => {
    const saveCurrentFormData = () => {
      setSaveCurrentFormData(() => () => {
        updateFormData({ bi6Option: selectedOption });
      });
    };
  
    saveCurrentFormData();
  }, [selectedOption, setSaveCurrentFormData, updateFormData]);
  
  return (
    <ContentBox>
      <ButtonBox>
        <PromptButton
          widthMobile='47%'
          heightMobile='10vw'
          fontSizeMobile='4vw'
          className={selectedOption === 'Utile' ? 'selected' : 'profileButton'}
          onClick={() => handleButtonClick('Utile')}
        >
          Utile
        </PromptButton>
        <PromptButton
          widthMobile='47%'
          heightMobile='10vw'
          fontSizeMobile='4vw'
          className={selectedOption === 'Humoristique' ? 'selected' : 'profileButton'}
          onClick={() => handleButtonClick('Humoristique')}
        >
          Humoristique
        </PromptButton>
        <PromptButton
          widthMobile='47%'
          heightMobile='10vw'
          fontSizeMobile='4vw'
          className={selectedOption === 'Ludique' ? 'selected' : 'profileButton'}
          onClick={() => handleButtonClick('Ludique')}
        >
          Ludique
        </PromptButton>
        <PromptButton
          widthMobile='47%'
          heightMobile='10vw'
          fontSizeMobile='4vw'
          className={selectedOption === 'Culturel' ? 'selected' : 'profileButton'}
          onClick={() => handleButtonClick('Culturel')}
        >
          Culturel
        </PromptButton>
        <PromptButton
          widthMobile='100%'
          heightMobile='10vw'
          fontSizeMobile='4vw'
          className={selectedOption === 'Personnalisé' ? 'selected' : 'profileButton'}
          onClick={() => handleButtonClick('Personnalisé')}
        >
          Personnalisé
        </PromptButton>
      </ButtonBox>
    </ContentBox>
  );
}

const PromptButton = styled('button')(({ theme, widthMobile, heightMobile, fontSizeMobile}) => ({
  boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)',
  width: '162px',
  height: '36px',
  fontSize: '15px',
  fontWeight: '600',
  '&.selected': {
    backgroundColor: '#FF7396', 
    borderRadius:'15px',
    color:"white",
  },
  "&:hover":{
    backgroundColor:'#FF7396'
  },
  '@media (max-width: 480px)': {
    width: widthMobile ? widthMobile : '162px',
    height: heightMobile ? heightMobile : '36px',
    fontSize: fontSizeMobile ? fontSizeMobile : '15px',

  },
}));

const ContentBox = styled(MuiBox)({
  height: '100%', 
  width: '100%', 
  backgroundColor: '#F7CB46', 
  display: 'flex', 
  flexDirection:'column',
  alignItems: 'center', 
  paddingTop:'5%',
  justifyContent: 'flex-start',
  gap:'10px', 
  position: 'relative',
  '@media (max-width: 480px)': {
    gap:'2vw',

  },
});

const ButtonBox = styled(MuiBox)(({ theme, widthMobile, heightMobile, fontSizeMobile}) => ({
  width: '50%', 
  height: '20%', 
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'space-evenly',
  flexWrap:'wrap',
  gap:'20px',
  zIndex:20,
  '@media (max-width: 480px)': {
    paddingTop:'25px',
    width: widthMobile ? widthMobile : '90%',
    height: heightMobile ? heightMobile : '60%',
    gap:'3vw',

  },
}));

export default BI_6;

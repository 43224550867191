import React, { useState } from 'react';
import { Box as MuiBox } from '@mui/material';
import { styled } from '@mui/system';
import styles from "./BotInterface.module.css"
import { useParams, useNavigate } from 'react-router-dom';
import BI_1 from './PageContent/BI_1';
import BI_2 from './PageContent/BI_2';
import BI_3 from './PageContent/BI_3';
import BI_4 from './PageContent/BI_4';
import BI_5 from './PageContent/BI_5';
import BI_6 from './PageContent/BI_6';
import BI_7 from './PageContent/BI_7';
import { useFormData } from '../provider/FormDataProvider';
import { API_URL } from '../config';
import useMediaQuery from '@mui/material/useMediaQuery';


function BotInterfaceWrapper() {
  const { page } = useParams(); 
  const isMobile = useMediaQuery('(max-width:480px)');  

  const currentPageNumber = page ? parseInt(page, 10) : 1;
  const navigate = useNavigate();
  const gradientPercentage = currentPageNumber * 14.1;
  const [saveCurrentFormData, setSaveCurrentFormData] = useState(() => () => {});
  const { formData } = useFormData();
  async function callOpenAiAPI(data) {
    const response = await fetch(`${API_URL}/openai-prompt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error('Failed to fetch from OpenAI');
    }
    return response.json();
  }
  
  console.log("Données du formulaire enregistrées :", formData);
  const handlePrev = () => {
    const newPage = Math.max(1, currentPageNumber - 1);
    saveCurrentFormData();
    navigate(`/prompt/${newPage}`);
  };

  const handleNext = async () => { 
    if (currentPageNumber === 7) {
      saveCurrentFormData();
      try {
        let apiResponse = await callOpenAiAPI(formData);
        console.log("Réponse de l'API :", apiResponse);
        apiResponse = { ...apiResponse, name: formData.name };
        navigate(`/giftresults`, { state: { apiResponse: apiResponse.products, name: apiResponse.name } });
      } catch (error) {
        console.error('Erreur lors de l\'appel à l\'API OpenAI', error);
      }
    } else {
      const newPage = Math.min(currentPageNumber + 1, 7);
      saveCurrentFormData();
      navigate(`/prompt/${newPage}`);
    }
  };
  
  
  const showSpecialCondition = !page || currentPageNumber === 1 || currentPageNumber === 4 || currentPageNumber === 6;
  const BIComponents = [BI_1, BI_2, BI_3, BI_4, BI_5, BI_6, BI_7];
  const CurrentBIComponent = BIComponents[currentPageNumber - 1] || BIComponents[0];
  const renderComicBubble = (currentPageNumber) => {
    switch (currentPageNumber) {
      case 1:
        return (
          <>
            <ComicBubble height='40%' isMobileHidden={true}>
            Bonjour ! Je m’appelle Gifty. Je vais t’aider à trouvre le cadeau parfait !
            </ComicBubble>
            <ComicBubble top='30%' left='47%' height='45%' leftMobile='42%' topMobile='20%' heightMobile='20vw'>
              {isMobile
              ? "Peux-tu me donner son prénom ainsi que son genre ?"
              : "Peux-tu me donner le prénom de la personne à qui tu veux offrir un cadeau ainsi que son genre ?"}
            </ComicBubble>
          </>
        );
      case 2:
        return (
          <ComicBubble top='30%' left='47%' height='20%' leftMobile='42%' topMobile='20%' heightMobile='20vw'>
            Super ! Peux-tu me donner son âge ?
          </ComicBubble>
        );
      case 3:
        return (
          <ComicBubble top='30%' left='47%' height='30%' leftMobile='42%' topMobile='20%' heightMobile='24vw'>
            Ah ouais il est jeune ! Et quel est ton lien avec cette personne ?
          </ComicBubble>
        );
      case 4:
        return (
          <ComicBubble top='30%' left='47%' height='40%' leftMobile='42%' topMobile='20%' heightMobile='24vw'>
            Pour quel type d’évènement souhaiterais-tu offrir ce cadeau ?
          </ComicBubble>
        );
      case 5:
        return (
          <>
            <ComicBubble top='30%' left='47%' height='35%' width='20%' leftMobile='42%' topMobile='20%' heightMobile='20vw'>
            {isMobile
              ? "Choisis les centres d’intérêts qui peuvent l'intéresser"
              : "A présent, choisis les centres d’intérêts qui seraient le plus susceptibles de l’intéresser !"}
            </ComicBubble>
            <ComicBubble top='45%' left='64%' height='35%' width='20%' leftMobile='42%' topMobile='60%' heightMobile='15vw'>
            {isMobile
              ? "Choisis-en 4 maximum !!"
              : "Choisis-en 4 maximum !! Ne t’en fais pas, tu pourras préciser ces catégories dans l’étape suivante"}
            </ComicBubble>
          </>
        );
      case 6:
        return (
          <>
            <ComicBubble height="15%" leftMobile='42%' topMobile='20%' heightMobile='12vw'>
              Parfait ! Je le note.
            </ComicBubble>
            <ComicBubble top='30%' left='47%' height='30%' leftMobile='42%' topMobile='46%' heightMobile='20vw'>
              Qu’elle serait le type de cadeau que tu aimerais offrir.
            </ComicBubble>
          </>
        );
      case 7:
        return (
          <>
            <ComicBubble top='30%' left='47%' height='15%' leftMobile='42%' topMobile='20%' heightMobile='13vw'>
              Quel est ton budget ?
            </ComicBubble>
          </>
        );
     
      default:
        return null;
    }
  };
  return (
    <div className={styles.mainDivPrompt}>
      <BackgroundBox>
        
        <div className={styles.stepBar} style={{ '--gradient-percentage': `${gradientPercentage}%` }}>
          {[...Array(7)].map((_, index) => {
            const circleClass = index + 1 === currentPageNumber ? `${styles.circle} ${styles.active}` : styles.circle;
            return (
              <div
                className={circleClass}
                key={index}
              >
                {index + 1}
              </div>
            );
          })}
        </div>
        <div className={styles.divGifto}>
          {renderComicBubble(currentPageNumber)}
          <img src='/images/gifto.png' alt='gifto' className={styles.imgGifto}/>
        </div>
      </BackgroundBox>
      <WaveBox />
      <LowerBackgroundBox>
        <CurrentBIComponent setSaveCurrentFormData={setSaveCurrentFormData} />
        <div className={styles.divPagination}>
          <PrevButton onClick={handlePrev}>Précédent</PrevButton>
          {/* {showSpecialCondition && (
            <div style={{ width: '50%' }}>
                <PromptInput className={styles.inputBot} id="other" name="other" placeholder='Autre réponse ...' type="text"/>
            </div>
          )} */}
          <NextButton onClick={handleNext}>Suivant</NextButton>
        </div>
      </LowerBackgroundBox>
    </div>
  );
}
const ComicBubble = styled('div')(({ theme, top, left, height, width, isMobileHidden, topMobile, leftMobile, heightMobile, fontSizeMobile }) => ({
  zIndex:15,
  position: 'absolute',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  flexDirection:'column',
  top: top || '30%',
  left: left || '15%',
  padding: '10px 20px',
  height: height || '30%',
  width: width || '15%',
  backgroundColor: 'white',
  borderRadius: '25px',
  border: '3px solid black',
  fontSize: '15px',
  fontWeight:'600',
  fontFamily: "'Poppins', sans-serif",
  color: 'black',
  maxWidth: '300px',
  boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)',
  '@media (max-width: 480px)': {
    display: isMobileHidden ? 'none' : 'flex',
    top: topMobile ? topMobile : '',
    left: leftMobile ? leftMobile : '',
    width:'53vw',
    height: heightMobile ? heightMobile : '',
    fontSize: fontSizeMobile ? fontSizeMobile : '3vw'
  },
}));
const BackgroundBox = styled(MuiBox)({
  height: '40vh', 
  width: '100%', 
  backgroundColor: '#FF7396', 
  position: 'relative',
  '@media (max-width: 480px)': {
    height: '40%', 

  },
});

const WaveBox = styled(MuiBox)({
  zIndex: 10,
  position: 'absolute',
  left: '0%',
  top: '38%',
  width: '100%',
  height: '13vh',
  backgroundImage: `url('/assets/vagueJauneRose.svg')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  '@media (max-width: 480px)': {
    height: '12vh',

  }
});

const LowerBackgroundBox = styled(MuiBox)({
  height: '51vh', 
  width: '100%', 
  backgroundColor: '#F7CB46', 
  position: 'relative',
  '@media (max-width: 480px)': {
    height: '60%', 

  },
});


const PromptInput = styled('input')({
  width: '100%', 
  height: '40px',
  padding: '0 20px',
  textTransform: 'none',
  border: '2px solid black',
  fontWeight: 700,
  borderRadius: '25px',
  fontFamily: "'Poppins', sans-serif",
  boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)',
  fontSize: '15px',
  backgroundColor: 'white',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  color: 'black',
  outline: 'none',
});
const PaginationButton = styled('button')({
  width: '150px',
  padding: '0 20px',
  height: '45px',
  textTransform: 'none',
  border: '2px solid black',
  fontWeight: 700,
  borderRadius: '25px',
  fontFamily: "'Poppins', sans-serif",
  boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)',
  fontSize: '20px',
  backgroundColor: '#FF7396',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  color: 'white',
  '&:hover': {
    backgroundColor: 'transparent', 
    color: 'black',
  },
  '&:active': {
    boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.5)', 
    transform: 'translateY(2px)',
  },
  '@media (max-width: 480px)': {
    fontSize: '5vw', 
    height: '5bw',
    padding: '0 2vw',


  },
});
const NextButton = styled(PaginationButton)({
  zIndex:15,
  marginRight: '10px',
  '@media (max-width: 480px)': {
    marginRight: '0',


  },
});

const PrevButton = styled(PaginationButton)({
  zIndex:15,
  marginLeft: '10px',
  '@media (max-width: 480px)': {
    marginLeft: '0',


  },
});

export default BotInterfaceWrapper;

import React, { useEffect, useState } from 'react';
import { Box, Box as MuiBox, Typography } from '@mui/material';
import { styled } from '@mui/system';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useLocation } from 'react-router-dom';
import axios from 'axios'; 
import { API_URL, decodeToken } from '../config';
import styles from './GiftResults.module.css';
function GiftResults() {
  const [user, setUser] = useState(null);
  const [notification, setNotification] = useState('');
  const [notificationClass, setNotificationClass] = useState('');
  const location = useLocation();
  const [products, setProducts] = useState([]); // État local pour les produits

  const recipient = location?.state?.name; 
  const notify = (message, type) => {
    setNotification(message);
    setTimeout(() => setNotification(''), 3000);
    if (type === 'green') {
      setNotificationClass('green-notify');
    } else {
      setNotificationClass('');
    }
  };
  useEffect(() => {
    setProducts(location?.state?.apiResponse);

    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      const decodedToken = decodeToken(authToken);
      setUser('/users/'+decodedToken.id);
    } 
  }, [user]); 
  

  const handleFavoriteToggle = async (index) => {
    const productToUpdate = products[index];
    const isFavorite = productToUpdate.isFavorite;
  
    try {
      if (isFavorite) {
        await axios.delete(`${API_URL}/history/${productToUpdate.historyId}`, {
          headers: { 'Content-Type': 'application/ld+json' },
        });
        notify(`"${productToUpdate.title}" supprimé des favoris.`, 'red');
        const updatedProducts = products.map((product, prodIndex) =>
          prodIndex === index ? { ...product, isFavorite: false, historyId: null } : product
        );
        setProducts(updatedProducts);
      }  else {
        const postData = {
          "user": user,
          "ideaLink": productToUpdate.itemWebUrl,
          "date": new Date().toISOString(),
          "recipient": recipient,
          "productName": productToUpdate.title
        };
        const response = await axios.post(`${API_URL}/history`, postData, {
          headers: { 'Content-Type': 'application/ld+json' },
        });
        if(response.data && response.data.id) {
          const historyId = response.data.id;
          notify(`"${productToUpdate.title}" ajouté aux favoris.`, 'green');
          const updatedProducts = [...products];
          updatedProducts[index] = { ...productToUpdate, isFavorite: !isFavorite, historyId: historyId };
          setProducts(updatedProducts);
        } else {
          throw new Error('ID de l\'histoire non retourné');
        }
      }
    } catch (error) {
      console.error('Erreur lors de la modification des favoris:', error);
      notify('Une erreur s\'est produite lors de la modification des favoris.', 'red');
    }
  };
  
  
  const renderComicBubble = () => {
        return (
          <>
            <ComicBubble top='25%' left='25%' height='15%' leftMobile='40%' topMobile='15%' heightMobile='auto'>
                J'ai trouvé des idées !
            </ComicBubble>
            <ComicBubble top='20%' left='60%' height='40%' opacity='0.5' leftMobile='40%' topMobile='35%' heightMobile='auto'>
                Clique sur une catégorie pour voir les articles proposés ! Tu peux aussi les conserver dans tes favorie.
            </ComicBubble>
            {/* <ComicBubble top='20%' left='80%' height='55%' opacity='0.5' leftMobile='41%' topMobile='20%' heightMobile='auto'>
                <span style={{fontWeight:'bold', fontSize:'15px', marginBottom:'10px'}}>Certains cadeaux ne te plaisent pas ?</span>
                Tu peux regénérer des idées ! En cliquant sur la catégorie , tu peux décider de les exclure de la prochaine génération.
            </ComicBubble> */}
          </>
        );
  };

  const defaultProducts = [
    { imageUrl: '/images/cadeau-man.png', title: 'Produit 1', price: { value: '10€', currency: 'EUR' }, itemWebUrl: '#', isFavorite: false },
    { imageUrl: '/images/cadeau-man.png', title: 'Produit 2', price: { value: '20€', currency: 'EUR' }, itemWebUrl: '#', isFavorite: false },
    { imageUrl: '/images/cadeau-man.png', title: 'Produit 3', price: { value: '30€', currency: 'EUR' }, itemWebUrl: '#', isFavorite: false },
    { imageUrl: '/images/cadeau-man.png', title: 'Produit 4', price: { value: '40€', currency: 'EUR' }, itemWebUrl: '#', isFavorite: false }
  ];
  
  return (
    <div className={styles.mainDivPrompt}>
      <BackgroundBox>
        <div className={styles.divGifto}>
          {renderComicBubble()}
          <img src='/images/giftoidea.png' alt='gifto' className={styles.imgGifto}/>
        </div>
      </BackgroundBox>
      <WaveBox />
      <LowerBackgroundBox>
          <CustomGiftContainer>
              {(products && products.length > 0 ? products : defaultProducts).map((product, index) => (
                <CustomGiftBox key={index}>
                    <ImagePlaceholder src={product.imageUrl} alt={product.title}/>
                    <DivGiftInfo>
                        <Typography fontSize={'25px'} fontWeight={'500'}>{product.title}</Typography>
                        <Typography fontSize={'20px'} fontWeight={'500'}>Prix : {product.price.value} {product.price.currency}</Typography> {/* Utiliser product.price */}
                    </DivGiftInfo>
                    <DivGiftAction>
                        <GiftButton height="50px" width="200px" onClick={() => window.open(product.itemWebUrl, "_blank")}>Voir l'article</GiftButton> {/* Ajout du lien vers l'article */}
                        <GiftButton
                          height="50px"
                          width="200px"
                          onClick={() => handleFavoriteToggle(index)} 
                          style={{
                            backgroundColor: product.isFavorite ? 'red' : 'white', 
                            color: product.isFavorite ? 'white' : 'black', 
                            transition: 'all 0.3s ease',
                          }}
                        >
                          {product.isFavorite ? 'Supprimer des favoris' : 'Ajouter aux favoris'}
                        </GiftButton>
                        {/* <GiftButton height='70px' width="75px"><DeleteOutlineIcon fontSize='large'/></GiftButton> */}
                    </DivGiftAction>
                </CustomGiftBox>
            ))}

        </CustomGiftContainer>
        {/* <GiftButton height="50px" width="250px">Regénérer des idées cadeaux</GiftButton> */}
      </LowerBackgroundBox>
      {notification && (
        <div className={`notify ${notificationClass}`}>
            {notification}
        </div>
      )}
    </div>
    
  );
}

const hexToRGBA = (hex, opacity) => {
    let r = 0, g = 0, b = 0;
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    }
    else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return `rgba(${r},${g},${b},${opacity})`;
  };

  const ImagePlaceholder = styled('img')({
    borderRadius: '15px 0 0 15px',
    width:'25%',
    height: '100%',
    objectFit: 'cover', 
    '@media (max-width: 480px)': {
      width:'100%',
      height:'50%'
  
    },
  });
  

const DivGiftInfo = styled(Box)({
    width:'50%',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'flex-start',
    gap:20,
    padding:'0 0 0 4%',
    '@media (max-width: 480px)': {
      padding:'5% 0 5% 6%',
      width:'100%',
      height:'25%'
    },
});

const DivGiftAction = styled(Box)({
    width:'25%',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    gap:'10%',
    '@media (max-width: 480px)': {
      width:'100%',
      height:'25%',
      marginBottom:'5vw',
      gap:'5vw',

    },

});
const GiftButton = styled('button')(({ width, height }) => ({
    boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)',
    backgroundColor:'white',
    borderRadius:'15px',
    width: width || '162px',
    height: height || '36px',
    fontSize: '15px',
    fontWeight: '600',
    cursor: 'pointer',
    transition: 'all 0.3s ease', 
    "&:hover": {
        backgroundColor: '#FF7396',
    },
    "&:active": {
        boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.5)',
        transform: 'translateY(2px)',
    },
    '@media (max-width: 480px)': {
      width:'90%',
      fontSize: '4vw',
      height:'12vw'
    
  
    },
}));
  
const BackgroundBox = styled(MuiBox)({
  height: '60vh', 
  width: '100%', 
  backgroundColor: '#FF7396', 
  position: 'relative',
  '@media (max-width: 480px)': {
    height: '40vh', 

  },
});
const CustomGiftContainer = styled(MuiBox)({  
    width: '80%',
    display:'flex',
    flexDirection:'column',
    gap:50,
    '@media (max-width: 480px)': {
      marginTop:'50px' 
  
    },
  });

const CustomGiftBox = styled(MuiBox)({
    display:'flex',
    width: '100%',
    height: '250px',
    textTransform: 'none',
    border: '2px solid',
    fontWeight: '700',
    borderRadius: '15px',
    boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)',
    fontSize: '24px',
    backgroundColor: 'white',
    '@media (max-width: 480px)': {
      flexDirection:'column',
      height: 'auto',
  
    },
  });

const ComicBubble = styled('div')(({ theme, top, left, height, width, bgColor = '#FFFFFF', opacity = 1, isMobileHidden, topMobile, leftMobile, heightMobile, fontSizeMobile }) => ({
    position: 'absolute',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'column',
    top: top || '30%',
    left: left || '15%',
    padding: '10px 20px',
    height: height || '30%',
    width: width || '15%',
    backgroundColor: hexToRGBA(bgColor, opacity),
    borderRadius: '25px',
    border: '3px solid black',
    fontSize: '15px',
    fontWeight:'600',
    fontFamily: "'Poppins', sans-serif",
    color: 'black',
    maxWidth: '300px',
    boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)',
    '@media (max-width: 480px)': {
      display: isMobileHidden ? 'none' : 'flex',
      top: topMobile ? topMobile : '',
      left: leftMobile ? leftMobile : '',
      width:'56vw',
      height: heightMobile ? heightMobile : '',
      fontSize: fontSizeMobile ? fontSizeMobile : '3vw'
    },
  }));
  
  
const WaveBox = styled(MuiBox)({
  zIndex: 10,
  position: 'absolute',
  left: '0%',
  // top: '60%',
  width: '100%',
  height: '20%',
  backgroundImage: `url('/assets/vagueJauneRose.svg')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
});

const LowerBackgroundBox = styled(MuiBox)({
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    gap:'5%',
    flexDirection:'column',
    height: '220vh', 
    width: '100%', 
    paddingTop:'5%',
    paddingBottom:'5%',
    backgroundColor: '#F7CB46', 
    position: 'relative',
    '@media (max-width: 480px)': {
      height:'auto',
      paddingBottom:'20vw',
    },
});


export default GiftResults;

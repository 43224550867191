import React, { useEffect, useState } from 'react';
import { AppBar, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { decodeToken } from '../../config';
import styles from './Header.module.css';
import ProfilHeader from './ProfilHeader';
import { styled } from '@mui/system';
import HomeIcon from '@mui/icons-material/Home';
import GiftIcon from '@mui/icons-material/CardGiftcard'; 
import FavoriteIcon from '@mui/icons-material/Favorite';
import InfoIcon from '@mui/icons-material/Info';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';

function Header() {
  const navigate = useNavigate();
  const [username, setUsername] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:480px)');  
  const handleNavigate = (path) => {
    navigate(path);
  };
  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    
    if (authToken) {
      const decodedToken = decodeToken(authToken);
      if (decodedToken && decodedToken.username) {
        setUsername(decodedToken.username);
      }
    } else {
      setUsername(null);
    }
  }, [username]);

  return (
    <StyledAppBar>
      <FlexBox>
        <LogoBox>
          <img src="/LOGO.png" alt="Logo" width="40" height="40" />
        </LogoBox>
        <img src={"/assets/separate.svg"} alt="Separation" className={styles.svgSeparate} />
        <MenuBox>
          {isMobile ? (
            <>
              <CustomIconButton onClick={() => handleNavigate('/')}><HomeIcon className={styles.headerIcons}/></CustomIconButton>
              <CustomIconButton onClick={() => handleNavigate('/prompt')}><GiftIcon className={styles.headerIcons}/></CustomIconButton>
              <CustomIconButton onClick={() => handleNavigate('/history')}><FavoriteIcon className={styles.headerIcons}/></CustomIconButton>
              <CustomIconButton onClick={() => handleNavigate('/learnmore')}><InfoIcon className={styles.headerIcons}/></CustomIconButton>
              {username ? (
                <ProfilHeader />
              ) : (
                <CustomIconButton onClick={() => handleNavigate('/login')}><AccountCircleIcon className={styles.headerIcons}/></CustomIconButton>
              )}
            </>
          ) : (
            <>
              <StyledButton color="inherit" onClick={() => handleNavigate('/')}>Accueil</StyledButton>
              <StyledButton color="inherit" onClick={() => handleNavigate('/prompt')}>Idée cadeau</StyledButton>
              <StyledButton color="inherit" onClick={() => handleNavigate('/history')}>Favoris</StyledButton>
              <StyledButton color="inherit" onClick={() => handleNavigate('/learnmore')}>En savoir plus</StyledButton>
              {username ? (
                <ProfilHeader />
              ) : (
                <StyledButton color="inherit" onClick={() => handleNavigate('/login')}>Connexion/Inscription</StyledButton>
              )}
            </>
          )}
        </MenuBox>
      </FlexBox>
    </StyledAppBar>
  );
}

const CustomIconButton = styled(IconButton)({
  height:'100%',

  padding:0,
});
const StyledAppBar = styled(AppBar)({
  backgroundColor: '#FF7396',
  height: '64px',
  zIndex: 1000,
  position: 'relative',
});

const FlexBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
});

const LogoBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '35%',
  paddingLeft: '20px',
  height: '100%',
  backgroundColor: '#F7CB46',
  '@media (max-width: 480px)': {
    width: '20%', // Exemple: changer la largeur pour les écrans mobiles
  },
});


const MenuBox = styled(Box)({
  backgroundColor: '#FF7396',
  height: "100%",
  width: '65%',
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  paddingLeft: '10px',
  '@media (max-width: 480px)': {
    width: '80%',
    justifyContent: 'space-between',
    marginRight:'25px',
  },

});

const StyledButton = styled(Button)({
  textTransform: 'none',
  fontSize: '16px',
});
export default Header;

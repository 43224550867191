import React, { useEffect, useState } from 'react';
import { Typography, Button, Box, Grid, Paper, Container, Avatar, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AccountCircle } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DeleteIconOutlined from '@mui/icons-material/DeleteOutlined'; 
import styles from './Home.module.css';
import { API_URL, decodeToken } from '../config';

const TitreSection = styled(Typography)({
  fontSize: '45px',
  fontWeight: 800,
  fontFamily: 'Poppins, sans-serif',
  color: 'white',
  textAlign: 'center',
  textShadow: '0 3px 0 black',
  mb: 6
});

const BoutonCartoon = styled(Button)({
  marginBottom: 5,
  marginTop: 15,
  padding: '10px 20px',
  borderRadius: 25,
  textTransform: 'none',
  backgroundColor: 'white',
  color: 'black',
  border: '2px solid black',
  boxShadow: '0 3px 0 black',
  fontFamily: 'Poppins, sans-serif',
  fontSize: '1.15rem',
  '&:hover': {
    filter: 'brightness(95%)'
  },
  '&:active': {
    boxShadow: '0 0px 0px black',
    transform: 'translateY(2px)', 
    backgroundColor: 'white'
  },
  transition: 'box-shadow 0.2s, transform 0.2s',
});
const StyledBox = styled(Box)({
  flexGrow: 1,
  overflow: 'hidden',
  backgroundColor: '#F7CB46',
});

const StyledContainer = styled(Container)({
  display: 'flex', 
  marginTop: '100px',
  marginBottom: '100px',
  '@media (max-width: 480px)': {
    height:'40vh'
  },
 
});

const StyledContainerFirstBox = styled(Box)({
  flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', 
  '@media (max-width: 480px)': {
    width:'100%'
  },
});

const StyledContainerSecondBox = styled(Box)({
  flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 99,
  '@media (max-width: 480px)': {
    display:'none'
  },
});


const StyledFirstTransition = styled(Box)({
  position: 'absolute',
  overflow: 'hidden',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '200px',
  backgroundColor: '#FF7396',
  backgroundImage: `url('assets/yellow-wave.svg')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',

});
const StyleBoxAfterFirstTransition = styled(Box)({
  backgroundColor: '#FF7396', paddingTop: '100px', paddingBottom: '25px'
});

const CustomPaper = styled(Paper)({
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  position: 'relative',
  width: '100%',
  '&:before': {
    content: '""',
    display: 'block',
  },
  borderRadius: '25px',
  border: '2px solid black',
  boxShadow: '0 3px 0px 0 black',
});

const FirstPaperBox = styled(Box)({
  position: 'absolute',
  top: -20,
  left: -20,
  backgroundColor: '#F7CB46',
  borderRadius: '50%',
  width: 50,
  height: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1.75rem',
  border: '2px solid black',
  boxShadow: '-2px 2px 0px 0 black',
  fontFamily: 'Merriweather, sans-serif',
  zIndex: 2,
});
const SecondPaperBox = styled(Box)({
  width: '100%', 
  display: 'flex', 
  justifyContent: 'center', 
  position: 'absolute', 
  top: 0, left: '50%', 
  transform: 'translateX(-50%)', 
  maxHeight: '100%',
  maxWidth: '100%',
  marginTop: '20px', 

});

const FirstExtendTitreSection = styled(TitreSection)({
  fontSize: '45px', fontWeight: 800, fontFamily: 'Poppins, sans-serif', color: 'white', textAlign: 'center', marginBottom: '40px',
  '@media (max-width: 480px)': {
    position:'relative',
    zIndex:1000
  },
});

const DovanaTitle = styled(Typography)({
  color: '#FF7396', fontWeight: 1000, fontFamily: 'Poppins, sans-serif', fontSize: '120px', letterSpacing: '0.03em',
  textShadow: 
  `-3px -3px 0 #000,
    3px -3px 0 #000,
    -3px  3px 0 #000,
    3px  3px 0 #000,  
    -6px 8px 0 #000`,
    '@media (max-width: 480px)': {
      textAlign:'center',
      fontSize: '20vw'
    },
});
const GiftTypo = styled(Typography)({
  fontWeight: 800, marginBottom: '20px',
  '@media (max-width: 480px)': {
    textAlign:'center',
    fontSize: '6vw'
  },
});

const DescGiftTypo = styled(Typography)({
  paddingRight: '200px', fontWeight: 500,
  '@media (max-width: 480px)': {
    textAlign:'center',
    fontSize: '5vw',
    paddingRight: '0'
  },
});

const BoxBtnCartoon = styled(Box)({
  display: 'flex', width: '100%',
  '@media (max-width: 480px)': {
    justifyContent:'center',

  },
});


const FirstExtendBoutonCartoon = styled(BoutonCartoon)({
  backgroundColor: '#FF7396', color: 'white', zIndex: 99,
  '&:hover': { backgroundColor: '#FF7396'},
  '@media (max-width: 480px)': {
    fontSize:'4vw',

  },
});

const SecondExtendBoutonCartoon = styled(BoutonCartoon)(({ backgroundColor, textColor, hoverColor, width, mediaWidth }) => ({
  width: width ? width : '',
  backgroundColor: backgroundColor ? backgroundColor : '#F7CB46', color: textColor ? textColor : 'white', zIndex: 99,
  '&:hover': { backgroundColor: hoverColor ? hoverColor : '#F7CB46'},
  '@media (max-width: 480px)': {
    width: mediaWidth ? mediaWidth : ''
  },
}));

const StyledSecondTransition = styled(Box)({
  position: 'absolute',
  overflow: 'hidden',
  width: '100%',
  height: '200px',
  backgroundColor: '#F7CB46',
  backgroundImage: `url('assets/pink-wave.svg')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});
const StyledThirdTransition = styled(Box)({  
  position: 'absolute',
  overflow: 'hidden',
  width: '100%',
  height: '200px',
  backgroundColor: '#FF7396',
  backgroundImage: `url('assets/yellow-wave.svg')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

const GiftoImg = styled('img')({
  filter: 'drop-shadow(-10px 10px 0px rgba(0, 0, 0, 0.25))',

  
});
const HowWorkGrid = styled(Grid)({
  '@media (max-width: 480px)': {
    padding:'4%',

  },
});
const CustomPlaceholder = styled(Box)({

  borderRadius:'15px 15px 0 0',
  backgroundColor:'lightgray',
});

const ForumContainer = styled(Container)({
  paddingTop:'300px',
  '@media (max-width: 480px)': {
    paddingTop:'150px',

  },
});

const ForumBox = styled(Box)({
  display: 'flex', 
  height: '200px', 
  alignItems: 'center', 
  justifyContent: 'flex-start', 
  gap: '5px', 
  boxShadow: '4px 4px 4px 0 rgba(0, 0, 0, 0.25)', 
  backgroundColor: '#F7F7F7', 
  marginBottom: '20px', 
  borderRadius: '20px', 
  padding: '50px',
  '@media (max-width: 480px)': {
    justifyContent:'center',
    height: '475px', 
    flexDirection:'column',
    textAlign:'center',
    gap:'25px',
  },
});
const ForumAvatar = styled(Avatar)({
  backgroundColor: 'black', 
  width: 100, 
  height: 100, 
  marginRight:'50px',
  '@media (max-width: 480px)': {
    marginRight:'0',

  },
});
const ForumTextBox = styled(Box)({
  display: 'flex', 
  width:'100%', 
  flexDirection:'column', 
  paddingRight:'50px',
  '@media (max-width: 480px)': {
    paddingRight:'0',
    gap:'10px'

  },
});

const CustomTypography = styled(Typography)({
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 900,
  fontSize: '40px',
  color: "white",
  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.75)",
  '@media (max-width: 480px)': {
    zIndex:100,

  },
      

});


const CustomHistoryContainer = styled(Box)(({ theme }) => ({
  width:'90%',
  minHeight:'30vh',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems:'center',
  gap: theme.spacing(4), 
  boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)',
  backgroundColor: '#F7CB46',
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(5),
  margin:'5px',
  zIndex:10,

}));


const BoxHistoryMain = styled(Box)({
  paddingTop: '300px', paddingBottom: '150px', backgroundColor: '#FF7396', display:'flex', justifyContent:'center', alignItems:'center',
  '@media (max-width: 480px)': {
    paddingTop: '150px',
  },
});
const TypographyDescForum = styled(Typography)({
  fontSize: '16px', fontWeight: '300', textAlign:'justify',
  '@media (max-width: 480px)': {
    fontSize:'3.5vw'

  },
      

});





export default function HomePage() {
  const [username, setUsername] = React.useState(null);
  const [histories, setHistories] = useState([]);
  const [user, setUser] = useState(null);
  const [notification, setNotification] = useState('');
  const [notificationClass, setNotificationClass] = useState('');
  const navigate = useNavigate();

  const notify = (message, type) => {
    setNotification(message);
    setTimeout(() => setNotification(''), 3000);
    if (type === 'green') {
      setNotificationClass('green-notify');
    } else {
      setNotificationClass('');
    }
  };
  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      const decodedToken = decodeToken(authToken);
      const userId = decodedToken.id;
  
      axios.get(`${API_URL}/history?user=${userId}`, {
        headers: { 
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (response.data && Array.isArray(response.data['hydra:member'])) {
          setHistories(response.data['hydra:member']); 
        } else {
          console.error("La structure de la réponse de l'API n'est pas celle attendue:", response.data);
          setHistories([]); 
        }
      })
      .catch(error => {
        console.error("Erreur lors de la récupération de l'historique:", error);
        setHistories([]); 
      });
    }
  }, [user]); 
  const handleFavoriteToggle = async (index) => {
    console.log(index);
    try {
        await axios.delete(`${API_URL}/history/${index}`, {
          headers: { 'Content-Type': 'application/ld+json' },
        });
        notify(`L'article a été supprimé des favoris.`, 'red');
        window.location.reload();
      } catch (error) {
      console.error('Erreur lors de la modification des favoris:', error);
      notify('Une erreur s\'est produite lors de la modification des favoris.', 'red');
    }
  };
  React.useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    
    if (authToken) {
      const decodedToken = decodeToken(authToken);
      if (decodedToken && decodedToken.username) {
        setUsername(decodedToken.username);
      }
    } else {
      setUsername(null);
    }
  }, [username]);
  return (
    <StyledBox>
      {/* Partie supérieure avec le titre et le bouton */}
      <StyledContainer maxWidth="lg">
        <StyledContainerFirstBox>
          <DovanaTitle id="dovanaTitle" variant="h1" gutterBottom>Dovana</DovanaTitle>
          <GiftTypo variant="h4">À court d&apos;idées de cadeaux ?</GiftTypo>
          <DescGiftTypo variant="h5" gutterBottom>Réponds aux questions de Gifto et il te trouvera les meilleurs cadeaux !</DescGiftTypo>
          <BoxBtnCartoon>
            <FirstExtendBoutonCartoon>Trouve ton idée de cadeau</FirstExtendBoutonCartoon>
          </BoxBtnCartoon>
        </StyledContainerFirstBox>
        <StyledContainerSecondBox>
          <GiftoImg src="/images/logo.png" alt="Logo Dovana" width="300" height="300" />
        </StyledContainerSecondBox>
      </StyledContainer>
      {/* Transition 1 */}
      <StyledFirstTransition/>
      {/* Partie centrale avec les Paper components */}
      <StyleBoxAfterFirstTransition>
        <Container maxWidth="md">
          <FirstExtendTitreSection variant="h4">Comment ça fonctionne ?</FirstExtendTitreSection>
          <HowWorkGrid container spacing={3} justifyContent="center">
            {Array.from({ length: 3 }).map((_, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <CustomPaper>
                  {/* Numéro en haut à gauche de chaque Paper */}
                  <FirstPaperBox>{index + 1}</FirstPaperBox>
                  {/* Placeholder pour l'image */}
                  <SecondPaperBox>
                    <CustomPlaceholder width={'85%'} height={150}/>
                  </SecondPaperBox>
                  {/* Titre et texte */}
                  <Box sx={{ mt: 19 }}>
                  <Typography variant="h5" gutterBottom textAlign="center" sx={{ fontWeight: 550 }}>
                    {index === 0 ? "Réponds aux questions de Gifto" : index === 1 ? "Sélectionne ton cadeau" : "Régénère ta réponse !"}
                  </Typography>
                  <Typography textAlign="center">
                    {index === 0 ? "Afin de trouver le cadeau idéal, tu dois répondre aux questions de Gifto et donner le plus de spécificités possibles sur la personne concernée." :
                      index === 1 ? "Gifto t'a préparé une sélection des meilleurs produits ! Tu peux cliquer sur chaque catégorie pour accéder aux différents produits." :
                        "Si certains cadeaux ne te plaisent pas, tu peux décider de générer d'autres cadeaux en excluant ceux que tu n'aimes pas (option réservée aux membres)."}
                  </Typography>
                  </Box>
                </CustomPaper>
              </Grid>
            ))}
          </HowWorkGrid>
        </Container>
      </StyleBoxAfterFirstTransition>
      
      {/* Transition 2 */}
      <StyledSecondTransition/>

      <ForumContainer maxWidth="lg">
        <Box sx={{ display: 'flex', justifyContent: 'center', }}>
          <FirstExtendTitreSection variant="h4">Rejoins notre forum !</FirstExtendTitreSection>
        </Box>
        <ForumBox>
            <ForumAvatar>
                <AccountCircle sx={{ width: 100, height: 100 }} />
            </ForumAvatar>
            <ForumTextBox>
                <Typography variant="h6" gutterBottom style={{ fontSize: '24px', fontWeight: '600' }}>Sujet</Typography>
                <TypographyDescForum variant="p" gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud consectetur magna
                </TypographyDescForum>
            </ForumTextBox>
            <BoutonCartoon sx={{ fontSize: '1rem' }}>Répondre</BoutonCartoon>

        </ForumBox>
        <ForumBox>
            <ForumAvatar>
                <AccountCircle sx={{ width: 100, height: 100 }} />
            </ForumAvatar>
            <ForumTextBox sx={{ display: 'flex', width:'100%', flexDirection:'column', paddingRight:5}}>
                <Typography variant="h6" gutterBottom style={{ fontSize: '24px', fontWeight: '600' }}>Sujet</Typography>
                <TypographyDescForum variant="p" gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud consectetur magna
                </TypographyDescForum>
            </ForumTextBox>
            <BoutonCartoon sx={{ fontSize: '1rem' }}>Répondre</BoutonCartoon>
        </ForumBox>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <SecondExtendBoutonCartoon href='forum'>
          Accéder au Forum
        </SecondExtendBoutonCartoon>
        </Box>
      </ForumContainer>

      {/* Transition 3 */}
    <StyledThirdTransition/>
      <BoxHistoryMain>
        {username !== null && (
           <CustomHistoryContainer>
              <CustomTypography variant="h6" textAlign={'center'} gutterBottom>Mes Favoris</CustomTypography>
              {histories ? histories.map((history, index) => (
                <Box key={index} className={styles.boxHistory}>
                  <Box className={styles.contentWrapper}>
                    <Box className={styles.divLineHistory}>
                      <Box className={styles.dateHistory}>
                        {new Date(history.date).toLocaleDateString('fr-FR', {
                          year: 'numeric', month: '2-digit', day: '2-digit',
                        })}
                      </Box>
                      <Box className={styles.destHistory}>Pour : {history.recipient}</Box>
                    </Box>

                    <Box className={styles.divIdeaDelete}>
                      <Box className={styles.giftHistory}>Idées de cadeau : {history.productName}</Box>
                      <Box className={styles.deleteHistory} onClick={() => handleFavoriteToggle(history.id)}><DeleteIconOutlined fontSize="large" /></Box>
                    </Box>
                  </Box>
                </Box>
              )): ''}
               <SecondExtendBoutonCartoon width={'25%'} mediaWidth={'100%'} textColor='black' backgroundColor='white' href='history'>
                Accéder à mes Favoris
              </SecondExtendBoutonCartoon>
              {notification && (
            <div className={`notify ${notificationClass}`}>
                {notification}
            </div>
          )}
         </CustomHistoryContainer>
        )}
      </BoxHistoryMain>

    </StyledBox>
  );
}

import React, { useEffect } from 'react';
import { Box, Paper } from '@mui/material';
import styles from './AuthStyles.module.css';
import OrangeAuth from './OrangeAuth';
import AuthForm from './AuthForm';

function Auth({login}) {

  return (
    <div className={styles.authContainer}>
      <Paper elevation={3} className={styles.authPaper}>
        {login ? 
        <>
         <AuthForm connexion={true} textButton="Connexion"/>
         <Box className={styles.waves}/> 
         <OrangeAuth 
          title="Viens t’inscrire chez nous !" 
          description='En t’inscrivant, bénéficie d’encore plus d’idées de cadeaux et accède à tes précédentes recherches !' 
          textButton="Inscription" 
          connexion={true}
          />
        </>
         : 
         <>
         <OrangeAuth title="Welcome back !" textButton="Connexion"/>
         <Box className={styles.waves}/> 
         <AuthForm textButton="Inscription"/>
        </>
        }
       
      </Paper>
    </div>
  );
}

export default Auth;

import React, { useState, useEffect } from 'react';
import { Box as MuiBox } from '@mui/material';
import { styled } from '@mui/system';
import { useFormData } from '../../provider/FormDataProvider';

function BI_7({ setSaveCurrentFormData }) {
  const { formData, updateFormData } = useFormData();
  const [budgetMin, setBudgetMin] = useState(formData.budgetMin || '');
  const [budgetMax, setBudgetMax] = useState(formData.budgetMax || '');
  const [selectedBudgetOption, setSelectedBudgetOption] = useState(formData.selectedBudgetOption || '');

  useEffect(() => {
    const saveCurrentFormData = () => {
      updateFormData({ budgetMin, budgetMax, selectedBudgetOption });
    };
    setSaveCurrentFormData(() => saveCurrentFormData);
  }, [budgetMin, budgetMax, selectedBudgetOption, setSaveCurrentFormData, updateFormData]);

  const handleBudgetChange = (min, max) => {
    const newSelection = `${min}-${max}`;
    if (newSelection === selectedBudgetOption) {
      setBudgetMin('');
      setBudgetMax('');
      setSelectedBudgetOption('');
    } else {
      setBudgetMin(min);
      setBudgetMax(max);
      setSelectedBudgetOption(newSelection);
    }
  };

  const handleManualInput = (setter) => (e) => {
    setter(e.target.value);
    if (selectedBudgetOption) {
      setSelectedBudgetOption('');
    }
  };
  return (
  <ContentBox>
      <ButtonBox>
          <PromptButton
            widthMobile='47%'
            heightMobile='10vw'
            fontSizeMobile='4vw'
            className={selectedBudgetOption === '10-25' ? 'selected' : 'profileButton'}
            onClick={() => handleBudgetChange(10, 25)}
          >10 - 25 €</PromptButton>
          <PromptButton
            widthMobile='47%'
            heightMobile='10vw'
            fontSizeMobile='4vw'
            className={selectedBudgetOption === '25-50' ? 'selected' : 'profileButton'}
            onClick={() => handleBudgetChange(25, 50)}
          >25 - 50 €</PromptButton>
          <PromptButton
            widthMobile='47%'
            heightMobile='10vw'
            fontSizeMobile='4vw'
            className={selectedBudgetOption === '50-100' ? 'selected' : 'profileButton'}
            onClick={() => handleBudgetChange(50, 100)}
          >50 - 100 €</PromptButton>
          <PromptButton
            widthMobile='47%'
            heightMobile='10vw'
            fontSizeMobile='4vw'
            className={selectedBudgetOption === '100-200' ? 'selected' : 'profileButton'}
            onClick={() => handleBudgetChange(100, 200)}
          >100 - 200 €</PromptButton>
      </ButtonBox>
      <ButtonBox>
          <CustomYearPrompt widthMobile='100%' heightMobile='15vw' fontSizeMobile='4vw'>Entrez votre budget
            <BudgetSelector>
              <BudgetMinInput type="text" value={budgetMin} onChange={handleManualInput(setBudgetMin)} />
              Et
              <BudgetMaxInput type="text" value={budgetMax} onChange={handleManualInput(setBudgetMax)} />
            </BudgetSelector>
          </CustomYearPrompt>
      </ButtonBox>
  </ContentBox>
);
}
const PromptButton = styled('button')(({ theme, widthMobile, heightMobile, fontSizeMobile}) => ({
  boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)',
  width: '162px',
  height: '36px',
  fontSize: '15px',
  fontWeight: '600',
  '&.selected': {
    backgroundColor: '#FF7396', 
    borderRadius:'15px',
    color:"white",
  },
  "&:hover":{
    backgroundColor:'#FF7396'
  },
  '@media (max-width: 480px)': {
    width: widthMobile ? widthMobile : '162px',
    height: heightMobile ? heightMobile : '36px',
    fontSize: fontSizeMobile ? fontSizeMobile : '15px',

  },
}));

const CustomYearPrompt = styled(MuiBox)(({ theme, widthMobile, heightMobile, fontSizeMobile}) => ({
  display:'flex',
  alignItems:'center',
  justifyContent:'space-between',
  width: '50%',
  textAlign: 'left',
  paddingLeft: '2%',
  height: '40px',
  textTransform: 'none',
  border: '2px solid',
  fontWeight: '700',
  borderRadius: '15px',
  boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)',
  fontSize: '15px',
  backgroundColor: 'white',
  '@media (max-width: 480px)': {
    width: widthMobile ? widthMobile : '100%',
    height: heightMobile ? heightMobile : '36px',
    fontSize: fontSizeMobile ? fontSizeMobile : '15px',
    paddingLeft: '5%',
    
  },
}));

const BudgetSelector = styled(MuiBox)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight:'3%',
  
});


const BudgetInput = styled('input')({
  width: '50px',
  height: '30px',
  textAlign: 'center',
  fontSize: '16px',
  border: '2px solid',
  borderBottom: '2px solid',
  outline:'none',
  '@media (max-width: 480px)': {
    height: '40px',
    fontSize: '20px',
  },
});

const BudgetMinInput = styled(BudgetInput)({
  borderRadius:'15px 0 0 15px',
  marginRight:'5%',

});
const BudgetMaxInput = styled(BudgetInput)({
  borderRadius:'0 15px 15px 0',
  marginLeft:'5%',

});

const ContentBox = styled(MuiBox)({
  height: '100%', 
  width: '100%', 
  backgroundColor: '#F7CB46', 
  display: 'flex', 
  flexDirection:'column',
  alignItems: 'center', 
  paddingTop:'5%',
  justifyContent: 'flex-start',
  gap:'15%', 
  position: 'relative',
  '@media (max-width: 480px)': {
    height:'60%',
    gap:'2vw', 

  },
});

const ButtonBox = styled(MuiBox)(({ theme, widthMobile, heightMobile, fontSizeMobile}) => ({
  width: '50%', 
  height: '20%', 
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'space-evenly',
  flexWrap:'wrap',
  gap:'20px',
  zIndex:20,
  '@media (max-width: 480px)': {
    paddingTop:'25px',
    width: widthMobile ? widthMobile : '90%',
    height: heightMobile ? heightMobile : '80%',
    gap:'3vw',

  },
}));


export default BI_7;

import React from 'react';
import { Typography, Avatar, Box } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';

import { styled } from '@mui/system';
import '../../App.css';

function LeftAvatar({ avatarUrl }) {
  return (
    <StyledBox>
      <StyledTypography variant="h6" gutterBottom>
        Ma photo de profil
      </StyledTypography>
      <StyledAvatar src={avatarUrl || ''}>
        {!avatarUrl && <AccountCircle sx={{ width: 90, height: 90 }} />}
      </StyledAvatar>
      <ProfileButton className="profileButton">
        Changer de photo
      </ProfileButton>
    </StyledBox>
  );
}

const StyledBox = styled(Box)({
  display: 'flex',
  height: '47%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '16px', 
  boxShadow: '4px 4px 4px 0 rgba(0, 0, 0, 0.25)',
  marginBottom: '40px', 
  borderRadius: '20px',
  padding: '32px',
  backgroundColor: '#FF7396',
  color: 'white',
});

const StyledAvatar = styled(Avatar)({
  width: '90px',
  height: '90px',
});

const StyledTypography = styled(Typography)({
  fontSize: '24px',
  fontWeight: '600',
});

const ProfileButton = styled('button')(({ theme }) => ({
  width: '162px',
  height: '36px',
  fontSize: '15px',
  fontWeight: '600',
}));
export default LeftAvatar;
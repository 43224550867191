import React from 'react';
import {Box} from '@mui/material';
import styles from './AuthStyles.module.css';
import { useNavigate } from 'react-router-dom';

function OrangeAuth({title, description, textButton, connexion}) {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (connexion) {
      navigate('/register');
    } else {
      navigate('/login');
    }
  };
  return (
        <Box className={styles.leftBox}>
          <h4 className={styles.welcomeBack} style={{ textAlign: connexion ? 'right' : 'left', marginTop: connexion ? '70px' : '110px', lineHeight: connexion ? '90px' : 'none' }}>
            {title}
          </h4>
          {description ?
          <p style={{margin: 0, marginTop: 20, paddingRight: connexion ? '110px' : 'none', textAlign: connexion ? 'right' : 'left'}}>{description}</p>
          : ''}
          <button className={styles.connexionButton} onClick={handleButtonClick} style={{marginTop: connexion ? '60px' : '90px', zIndex:10 }}>
            {textButton}
          </button>
        </Box>
  );
}

export default OrangeAuth;

import {API_URL, FRONT_URL} from '../../config';

let popupWindow = null;

export const handleFacebookLogin = () => {
  if (!popupWindow || popupWindow.closed) { 
    const width = 600;
    const height = 400;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const url = `${API_URL}/connect/facebook`;
    const features = `width=${width},height=${height},left=${left},top=${top}`; 

    popupWindow = window.open(
      url,
      'Facebook Login',
      features
    );
    
    if (popupWindow) {
      window.addEventListener('message', (event) => {
          if (event.origin !== `${API_URL}`) return;
          if (event.data.token) {
              localStorage.setItem('authToken', event.data.token);
              if (popupWindow) {
                  popupWindow.close(); 
              }
              window.location.href = `${FRONT_URL}`;
          }
      }, false);

  } else {
      console.error('La fenêtre popup a été bloquée par le navigateur.');
  } 
  }
};

import React, { useState } from 'react';
import { Box } from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { styled } from '@mui/system';
import {jwtDecode} from 'jwt-decode'; 
import { API_URL } from '../../config.js';
import axios from 'axios';
  
function Password() {
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
    const [notification, setNotification] = useState('');
    const [notificationClass, setNotificationClass] = useState('');
    const handleTogglePasswordVisibility = (confirmation) => () => {
        if (confirmation) {
            setShowPasswordConfirmation(!showPasswordConfirmation);
        } else {
            setShowPassword(!showPassword);
        }
    };
    const notify = (message, type) => {
        setNotification(message);
        setTimeout(() => setNotification(''), 3000);
        if (type === 'green') {
          setNotificationClass('green-notify');
        } else {
          setNotificationClass('');
        }
      };

    const handlePasswordChange = () => {
        if (password !== passwordConfirmation) {
            notify('Les mots de passe ne correspondent pas.');
            return;
        }
        const newPassword = {
            password: password,
        };

        const token = localStorage.getItem('authToken');
        const decoded = jwtDecode(token);
        const userId = decoded.id;
        axios.patch(`${API_URL}/users/${userId}`, newPassword, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/merge-patch+json'
            }
            })
            .then(response => {
            notify('Mot de passe mis à jour avec succès', 'green');
            setPassword('');
            setPasswordConfirmation('');
            setShowPassword(false);
            setShowPasswordConfirmation(false);
            })
            .catch(error => {
            console.error('Erreur lors de la mise à jour du mot de passe', error);
            });
    };

    return (
        <StyledBox>
             {notification && (
                <div className={`notify ${notificationClass}`}>
                    {notification}
                </div> 
                )}
            <DivPassword>
                <FormRow>
                    <Label htmlFor="password">Nouveau mot de passe</Label>
                    <InputContainer>
                        <StyledInput width='100%' id="password"name="password"placeholder='Mot de passe'type={showPassword ? "text" : "password"}value={password}onChange={e => setPassword(e.target.value)}required/>
                        <CustomIconButton onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <VisibilityOffOutlined sx={{ color: 'black' }} /> : <VisibilityOutlined sx={{ color: 'black' }} />}
                        </CustomIconButton>
                    </InputContainer>
                </FormRow>

                <FormRow>
                    <Label htmlFor="password">Nouveau mot de passe</Label>
                    <InputContainer>
                        <StyledInput width='100%' name="passwordConfirmation" placeholder='Confirmation' type={showPasswordConfirmation ? "text" : "password"} value={passwordConfirmation} onChange={e => setPasswordConfirmation(e.target.value)} required/>
                        <CustomIconButton onClick={handleTogglePasswordVisibility(true)}>
                            {showPasswordConfirmation ? <VisibilityOffOutlined style={{ color: 'black' }} /> : <VisibilityOutlined style={{ color: 'black' }} />}
                        </CustomIconButton>
                    </InputContainer>
                </FormRow>
                <StyledButton onClick={handlePasswordChange}>Modifier le mot de passe</StyledButton>

            </DivPassword>
        </StyledBox>
    );
}

const StyledBox = styled(Box)({
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center',
    paddingTop: '20px', 
    color:'white'
});

const DivPassword = styled('div')({
    display:'flex', width:'100%', alignItems:'center',
    '@media (max-width: 480px)': {
        flexDirection:'column',
        gap:'5vw'
      },
});


const StyledButton = styled('button')({
    width: '225px', height: '36px', fontSize: '15px', fontWeight: '600', borderRadius: '15px', 
    boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)', backgroundColor: '#F7CB46', marginTop: '30px', marginLeft:'15px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
        backgroundColor: '#FF7396',
    },
    '&:active': {
        boxShadow: '0px 2px 0px rgba(0, 0, 0, 1)',
        transform: 'translateY(2px)'
    },
    '@media (max-width: 480px)': {
        margin:0,
        width:'100%',
        height: '50px',
    },
});
const Label = styled('label')({
    width:'100%',
    marginBottom: '5px', display: 'block', color: '#333',
    '@media (max-width: 480px)': {
        width:'100%'
      },
});
const FormRow = styled('div')(({ width, margin, display}) => ({
    display: display || 'block', flexDirection: 'row', width:width || "40%", margin: margin || '0',
    '@media (max-width: 480px)': {
        width:'100%'
    },
}));

const InputContainer = styled('div')({
    position: 'relative', display: 'flex',
    alignItems: 'center', width: '90%',
    '@media (max-width: 480px)': {
        width:'100%'
      },
});


const CustomIconButton = styled('div')({
    position: 'absolute', 
    top: '57%', 
    right: '5%', 
    transform: 'translateY(-50%)'
});

const StyledInput = styled('input')(({ width }) => ({
    borderRadius: '10px', padding: '10px', width: width || '400px', height: '45px', fontFamily: 'Poppins, sans-serif', 
    fontWeight: '600', border: '2px solid black', transition: 'border-color 0.3s', fontSize: '15px',
    '&:focus': {
        outline: 'none', borderColor: '#F7CB46', backgroundColor: '#fff4d4',
    }
}));
  

export default Password;

import { API_URL } from '../../config.js';
import axios from 'axios';

export const initializeGoogleLogin = (handleGoogleSuccess, handleGoogleFailure, navigate, notify) => {
  const script = document.createElement('script');
  script.src = "https://accounts.google.com/gsi/client";
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);

  script.onload = () => {
    window.google.accounts.id.initialize({
      client_id: "942893376736-qqblhh2mogs016ehjnq4p6fvase46i9o.apps.googleusercontent.com",
      callback: (response) => handleGoogleSuccess(response, navigate, notify), // Pass notify here
    });
  };

  return () => {
    document.body.removeChild(script);
  };
};

export const handleGoogleLogin = () => {
  window.google.accounts.id.prompt((notification) => {
    if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
      document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
      window.google.accounts.id.prompt();
    }
  });
};

export const handleGoogleFailure = (error, notify) => {
  console.error('Google Login Failed', error);
  notify("Échec de la connexion Google. Veuillez réessayer.");
};
export const handleGoogleSuccess = (response, navigate, notify) => {
  const { credential } = response;
  axios.post(`${API_URL}/api/auth/google`, { token: credential }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then(response => {
    localStorage.setItem('authToken', response.data.token);
    navigate('/');
    window.location.reload();
  })
  .catch(error => {
    console.error('Google Login Failed', error);
    notify("Échec de la connexion Google. Veuillez réessayer.");
  });
};
  

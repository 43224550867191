import React, { useState, useEffect } from 'react';
import { Box as MuiBox } from '@mui/material';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useFormData } from '../../provider/FormDataProvider';

function BI_2({ setSaveCurrentFormData }) {
  const { formData, updateFormData } = useFormData();

  const [age, setAge] = useState(formData.age || 0);
  const [selectedCategory, setSelectedCategory] = useState(formData.category || '');

  useEffect(() => {
    setSaveCurrentFormData(() => () => {
      updateFormData({ age, category: selectedCategory });
    });
  }, [age, selectedCategory, setSaveCurrentFormData, updateFormData]);

  const handleDecrease = () => setAge((prevAge) => Math.max(0, prevAge - 1));
  const handleIncrease = () => setAge((prevAge) => prevAge + 1);

  const handleCategoryClick = (category) => {
    setSelectedCategory((prevCategory) => (prevCategory === category ? '' : category));
  };

  const handleAgeChange = (e) => {
    const newAge = parseInt(e.target.value, 10);
    setAge(!isNaN(newAge) && newAge >= 0 ? newAge : 0);
  };
  return (
    <ContentBox>
      <ButtonBox widthMobile='90%'>
        <PromptButton

          widthMobile='47%'
          heightMobile='10vw'
          fontSizeMobile='4vw'
          className={selectedCategory === 'Nourrisson' ? 'selected' : 'profileButton'}
          onClick={() => handleCategoryClick('Nourrisson')}
        >
          Nourrisson
        </PromptButton>
        <PromptButton
          widthMobile='47%'
          heightMobile='10vw'
          fontSizeMobile='4vw'
          className={selectedCategory === 'Enfant' ? 'selected' : 'profileButton'}
          onClick={() => handleCategoryClick('Enfant')}
        >
          Enfant
        </PromptButton>
        <PromptButton
          widthMobile='47%'
          heightMobile='10vw'
          fontSizeMobile='4vw'
          className={selectedCategory === 'Adolescent' ? 'selected' : 'profileButton'}
          onClick={() => handleCategoryClick('Adolescent')}
        >
          Adolescent
        </PromptButton>
        <PromptButton
          widthMobile='47%'
          heightMobile='10vw'
          fontSizeMobile='4vw'
          className={selectedCategory === 'Adulte' ? 'selected' : 'profileButton'}
          onClick={() => handleCategoryClick('Adulte')}
        >
          Adulte
        </PromptButton>
        <PromptButton
          widthMobile='100%'
          heightMobile='10vw'
          fontSizeMobile='4vw'
          className={selectedCategory === 'Personne âgée' ? 'selected' : 'profileButton'}
          onClick={() => handleCategoryClick('Personne âgée')}
        >
          Personne âgée
        </PromptButton>
        <CustomYearPrompt>Tu connais son âge ?
          <AgeSelector>
            <AgePrevButton onClick={handleDecrease}><RemoveIcon /></AgePrevButton>
            <AgeInput type="text" value={age.toString()} onChange={handleAgeChange} />
            <AgeNextButton onClick={handleIncrease}><AddIcon /></AgeNextButton>
          </AgeSelector>
        </CustomYearPrompt>
      </ButtonBox>
      

    </ContentBox>
  );
}

const PromptButton = styled('button')(({ theme, widthMobile, heightMobile, fontSizeMobile}) => ({
  boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)',
  width: '31%',
  height: '36px',
  fontSize: '15px',
  fontWeight: '600',
  '&.selected': {
    backgroundColor: '#FF7396', 
    borderRadius:'15px',
    color:"white",
  },
  "&:hover": {
    backgroundColor: '#FF7396',
  },
  '@media (max-width: 480px)': {
    width: widthMobile ? widthMobile : '162px',
    height: heightMobile ? heightMobile : '36px',
    fontSize: fontSizeMobile ? fontSizeMobile : '15px',

  },
}));

const CustomYearPrompt = styled(MuiBox)(({ theme, widthMobile, heightMobile, fontSizeMobile}) => ({
  display:'flex',
  alignItems:'center',
  justifyContent:'space-between',
  width: '50%',
  textAlign: 'left',
  paddingLeft: '2%',
  height: '40px',
  textTransform: 'none',
  border: '2px solid',
  fontWeight: '700',
  borderRadius: '15px',
  boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)',
  fontSize: '15px',
  backgroundColor: 'white',
  '@media (max-width: 480px)': {
    width: widthMobile ? widthMobile : '100%',
    height: heightMobile ? heightMobile : '12vw',
    fontSize: fontSizeMobile ? fontSizeMobile : '4vw',
    paddingLeft: '25px'

  },
}));

const AgeSelector = styled(MuiBox)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight:'3%'
});

const AgeButton = styled('button')({
  outline:'none',
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  width: '30px',
  height: '30px',
  fontSize: '20px',
  fontWeight: 'bold',
  backgroundColor:'white',
  cursor:'pointer',
  '&:hover': {
    backgroundColor:'#F7CB46',
    transition: 'all 0.3s ease'
  },
  '@media (max-width: 480px)': {
    width:'10vw',
    height: '40px',
    fontSize: '20px',

  },
});

const AgeNextButton = styled(AgeButton)({
  borderRadius: '0 15px 15px 0'
  
});

const AgePrevButton = styled(AgeButton)({
  borderRadius: '15px 0 0 15px '
});

const AgeInput = styled('input')({
  width: '50px',
  height: '30px',
  textAlign: 'center',
  fontSize: '16px',
  border:'none',
  borderTop: '2px solid',
  borderBottom: '2px solid',
  outline:'none',
  '@media (max-width: 480px)': {
    height: '40px',
    fontSize: '5vw',

  },
});


const ContentBox = styled(MuiBox)({
  height: '80%', 
  width: '100%', 
  backgroundColor: '#F7CB46', 
  display: 'flex', 
  flexDirection:'column',
  alignItems: 'center', 
  justifyContent: 'center',
  gap:'5px', 
  position: 'relative',
  '@media (max-width: 480px)': {
    gap:'1vw', 
    height: '20%',
  },
});

const ButtonBox = styled(MuiBox)(({ theme, widthMobile, heightMobile, fontSizeMobile}) => ({
  width: '50%', 
  height: '100%', 
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'space-evenly',
  flexWrap:'wrap',
  gap:'20px',
  zIndex:20,
  '@media (max-width: 480px)': {
    width: widthMobile ? widthMobile : '50%',
    minHeight: 'auto',
    height: heightMobile ? heightMobile : '45%',
    fontSize: fontSizeMobile ? fontSizeMobile : '15px',
    gap:'5vw',

   
  },
}));


export default BI_2;

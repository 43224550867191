import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useParams } from 'react-router-dom'; // Import useParams
import Home from './Home/Home';
import Auth from './Auth/Auth';
import Layout from './Layout/Layout';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Profile from './Profile/Profile';
import History from './History/History';
import Forum from './Forum/Forum';
import Post from './Forum/Post/Post';
import BotInterface from './BotInterface/BotInterfaceWrapper';
import LearnMore from './LearnMore/LearnMore';
import GiftResults from './GiftResults/GiftResults';
import { FormDataProvider } from './provider/FormDataProvider';

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
  },
});

// Wrapper Component to use useParams hook
function BotInterfaceWrapper() {
  let { page } = useParams(); // Access the current page number
  return <BotInterface page={page} />; // Pass the page number to BotInterface
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
      <FormDataProvider>

        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Auth login={true}/>} />
            <Route path="/register" element={<Auth />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/history" element={<History />} />
            <Route path="/forum" element={<Forum />} />
            <Route path="/post" element={<Post />} />
            <Route path="/prompt" element={<BotInterfaceWrapper />} />
            <Route path="/prompt/:page" element={<BotInterfaceWrapper />} />
            <Route path="/learnmore" element={<LearnMore />} />
            <Route path="/giftresults" element={<GiftResults />} />


          </Routes>
        </Layout>
        </FormDataProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;

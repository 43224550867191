import React, { useState } from 'react';
import { ClickAwayListener, styled, InputBase, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
const DropdownMenu = ({ options, onSelect, placeholder = '', selectedValue, bgValueColor, colorValue, zIndexOptions, zIndexSelect }) => {
    const [selectedOption, setSelectedOption] = useState(selectedValue || '');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
  
    const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };
    
    const handleSelect = (value) => {
      setSelectedOption(value);
      setIsDropdownOpen(false);
      onSelect(value);
    };
  
    const toggleDropdown = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };
    const DropdownIcon = isDropdownOpen ? ExpandLessIcon : ExpandMoreIcon;

  
    return (
      <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
        <CustomSelect>
          <SelectValue onClick={toggleDropdown} bgValueColor={bgValueColor} colorValue={colorValue} zIndexSelect={zIndexSelect}>
            <span style={{width:'100%'}}>{selectedOption || placeholder}</span>
            <IconButton
                size="small"
                style={{ marginLeft: 'auto' }} // Ceci pousse l'icône vers la droite
            >
            <DropdownIcon />
          </IconButton>
          </SelectValue>
          {isDropdownOpen && (
            <OptionsBox zIndexOptions={zIndexOptions}>
              {options.map((option) => (
                <Option key={option.value} onClick={() => handleSelect(option.label)}>
                  {option.label}
                </Option>
              ))}
              <InputField
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Autre..."
              />
            </OptionsBox>
          )}
        </CustomSelect>
      </ClickAwayListener>
    );
  };
const CustomSelect = styled('div')({
    position: 'relative',
    outline:'none',
  
    textAlign:'center',
    width: '225px',
    height: '36px',
    fontSize: '15px',
    fontWeight: '600',
  
    '@media (max-width: 480px)': {
      width:'47%',
      height:'40%',
      fontSize: '20px',
  
    },
  });
  const SelectValue = styled('div')(({ bgValueColor, colorValue, zIndexSelect }) => ({
    paddingLeft:'10%',
    userSelect:'none',
    position: 'relative',
    display:'flex',
    alignItems:'center',
    boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)',
    borderRadius:'15px',
    backgroundColor: bgValueColor || 'white',
    color: colorValue || 'black',
    border: '2px solid black',
    width:'100%',
    height: '36px',
    cursor: 'pointer',
    zIndex: 10,
    '@media (max-width: 480px)': {
      width:'100%',
      height:'100%',
      fontSize: '20px',
      zIndex: zIndexSelect ? zIndexSelect : ''
    },
  }));
  const OptionsBox = styled('div')(({ zIndexOptions }) => ({
    position: 'absolute',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center',
    top: '30%',
    left: '0',
    gap:'15px',
    height:'34vh',
    border: '2px solid black',
    padding:'20% 0 5% 0',
    borderRadius: '15px',
    width: '100%',
    
    backgroundColor:'#FF7396',
    '@media (max-width: 480px)': {
      zIndex: zIndexOptions ? zIndexOptions : '',

    }
  }));
  const Option = styled('div')({
    padding: '5px',
    border: '2px solid black',
    borderRadius: '15px',
    textAlign: 'center',
    cursor: 'pointer',
    boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)',
    width: '80%',
    fontSize: '15px',
    fontWeight: '600',
    backgroundColor:'white',
    '&:hover': {
      backgroundColor: '#f0f0f0',
      borderRadius: '15px',
      
    },
    '@media (max-width: 480px)': {
      fontSize: '3vw',

    }

  });
  
  const InputField = styled(InputBase)({
    boxShadow: '0px 4px 0px rgba(0, 0, 0, 0.1)',
    border: '2px solid black',
    borderRadius: '15px',
    width: '90%', 
    height: '36px',
    padding: '0 12px',
    margin: '0 10px',
    fontSize: '15px',
    fontWeight: '600',
    backgroundColor:'white'
  });
export default DropdownMenu;
import React, { useState, useEffect } from 'react';
import { Box as MuiBox } from '@mui/material';
import { styled } from '@mui/system';
import { useFormData } from '../../provider/FormDataProvider';

function BI_1({ setSaveCurrentFormData }) {
  const { formData, updateFormData } = useFormData();  
  const [selectedOption, setSelectedOption] = useState(formData.gender || '');
  const [name, setName] = useState(formData.name || '');

  useEffect(() => {
    setSaveCurrentFormData(() => () => {
      updateFormData({ name, gender: selectedOption });
    });
  }, [name, selectedOption, setSaveCurrentFormData, updateFormData]);
  console.log(formData);
  const handleButtonClick = (value) => {
    setSelectedOption(selectedOption === value ? '' : value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  return (
    <ContentBox>
      <InputBox>
        <DivPrompt className="input-info-container">
          <PromptInput 
            id="prenom" 
            name="prenom" 
            placeholder='Prénom' 
            type="text"
            value={name}
            onChange={handleNameChange}
            heightMobile='10vw'
            fontSizeMobile='4vw'
          />
        </DivPrompt>
      </InputBox>
      
      <ButtonBox widthMobile='90%'>
        <PromptButton
          className={selectedOption === 'Homme' ? 'selected' : 'profileButton'}
          onClick={() => handleButtonClick('Homme')}
          widthMobile='47%'
          heightMobile='10vw'
          fontSizeMobile='4vw'
        >
          Homme
        </PromptButton>
        <PromptButton
          className={selectedOption === 'Femme' ? 'selected' : 'profileButton'}
          onClick={() => handleButtonClick('Femme')}
          widthMobile='47%'
          heightMobile='10vw'
          fontSizeMobile='4vw'
        >
          Femme
        </PromptButton>
        <PromptButton
          className={selectedOption === 'Ne pas préciser' ? 'selected' : 'profileButton'}
          onClick={() => handleButtonClick('Ne pas préciser')}
          widthMobile='100%'
          heightMobile='10vw'
          fontSizeMobile='4vw'
        >
          Ne pas préciser
        </PromptButton>
        
      </ButtonBox>
    </ContentBox>
  );
}

const PromptButton = styled('button')(({ theme, widthMobile, heightMobile, fontSizeMobile}) => ({
  boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)',
  width: '162px',
  height: '36px',
  fontSize: '15px',
  fontWeight: '600',
  '&.selected': {
    backgroundColor: '#FF7396', 
    borderRadius:'15px',
    color:"white",
  },
  "&:hover":{
    backgroundColor:'#FF7396'
  },
  '@media (max-width: 480px)': {
    width: widthMobile ? widthMobile : '162px',
    height: heightMobile ? heightMobile : '36px',
    fontSize: fontSizeMobile ? fontSizeMobile : '15px',

  },
}));

const DivPrompt = styled('div')({
  width: '50%',
  '@media (max-width: 480px)': {
    width:'100%',
  },
});

const ContentBox = styled(MuiBox)({
  height: '100%', 
  width: '100%', 
  backgroundColor: '#F7CB46', 
  display: 'flex', 
  flexDirection:'column',
  alignItems: 'center', 
  paddingTop:'4%',
  justifyContent: 'flex-start',
  gap:'10%',
  position: 'relative',
  
});

const InputBox = styled(MuiBox)({
  width: '30%', 
  height: '20%', 
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'center',
  '@media (max-width: 480px)': {
    width:'80%',
  },
});

const ButtonBox = styled(MuiBox)(({ theme, widthMobile, heightMobile, fontSizeMobile}) => ({
  width: '40%', 
  height: '20%', 
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'space-evenly',
  flexWrap:'wrap',
  zIndex:10,
  '@media (max-width: 480px)': {
    width: widthMobile ? widthMobile : '40%',
    height: heightMobile ? heightMobile : '20%',
    fontSize: fontSizeMobile ? fontSizeMobile : '15px',
    gap:'5vw'
  },
}));

const PromptInput = styled('input')(({ theme, widthMobile, heightMobile, fontSizeMobile}) => ({
  width: '100%',
  height: '40px',
  padding: '0 20px',
  textTransform: 'none',
  border: '2px solid black',
  fontWeight: 700,
  borderRadius: '25px',
  fontFamily: "'Poppins', sans-serif",
  boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)',
  fontSize: '15px',
  backgroundColor: 'white',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  color: 'black',
  outline: 'none',
  '@media (max-width: 480px)': {
    width: widthMobile ? widthMobile : '100%',
    height: heightMobile ? heightMobile : '40px',
    fontSize: fontSizeMobile ? fontSizeMobile : '15px',
    zIndex: 100,
  },
}));


export default BI_1;

import React, { useState } from 'react';
import { Typography, Box } from '@mui/material';
import {jwtDecode} from 'jwt-decode'; 
import { API_URL } from '../../config.js';
import axios from 'axios';
import { styled } from '@mui/system';



function LeftBio() {
    const [bio, setBio] = useState('');
    const [notification, setNotification] = useState('');
    const [notificationClass, setNotificationClass] = useState('');

    const notify = (message, type) => {
        setNotification(message);
        setTimeout(() => setNotification(''), 3000);
        if (type === 'green') {
          setNotificationClass('green-notify');
        } else {
          setNotificationClass('');
        }
      };

    const handleBioChange = () => {
        const newBio = {
            bio: bio,
        };

        const token = localStorage.getItem('authToken');
        const decoded = jwtDecode(token);
        const userId = decoded.id;
        axios.patch(`${API_URL}/users/${userId}`, newBio, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/merge-patch+json'
            }
            })
            .then(response => {
            notify('Mot de passe mis à jour avec succès', 'green');
            setBio(newBio);
            })
            .catch(error => {
            console.error('Erreur lors de la mise à jour du mot de passe', error);
            });
    };

    return (
        <StyledBox>
             {notification && (
                <div className={`notify ${notificationClass}`}>
                    {notification}
                </div> 
                )}
            <StyledTypography variant="h6" gutterBottom>Bio</StyledTypography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="input-container">
                    <StyledTextarea
                        name="bio"
                        placeholder='Décrivez-vous ...'
                        value={bio}
                        onChange={e => setBio(e.target.value)}
                        required
                    />
                </div>
            </div>
            <div>
                <ProfileButton className="profileButton" onClick={handleBioChange}>Enregistrer</ProfileButton>
            </div>
        </StyledBox>
    );
}
const StyledBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    height: '47%',
    alignItems: 'center',
    gap: '16px',
    boxShadow: '4px 4px 4px 0 rgba(0, 0, 0, 0.25)',
    backgroundColor: '#FF7396',
    borderRadius: '20px',
    padding: '25px',
    color: 'white',
  });
  
  const StyledTypography = styled(Typography)({
    fontSize: '24px',
    fontWeight: '600',
    textAlign: 'center',
  });
  
  const StyledTextarea = styled('textarea')({
    maxWidth: '250px',
    minWidth: '250px',
    minHeight: '150px',
    borderRadius: '10px',
    border: '2px solid black',
    padding: '1em',
  });
  
  const ProfileButton = styled('button')({
    width: '162px',
    height: '36px',
    fontSize: '15px',
    fontWeight: '600',
  });
export default LeftBio;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, Typography } from '@mui/material';
import DeleteIconOutlined from '@mui/icons-material/DeleteOutlined'; 
import styles from './History.module.css';
import { styled } from '@mui/system';
import { API_URL, decodeToken } from '../config';

function History() {
  const [histories, setHistories] = useState([]);
  const [user, setUser] = useState(null);
  const [notification, setNotification] = useState('');
  const [notificationClass, setNotificationClass] = useState('');
  const navigate = useNavigate();

  const notify = (message, type) => {
    setNotification(message);
    setTimeout(() => setNotification(''), 3000);
    if (type === 'green') {
      setNotificationClass('green-notify');
    } else {
      setNotificationClass('');
    }
  };
  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      const decodedToken = decodeToken(authToken);
      const userId = decodedToken.id;
  
      axios.get(`${API_URL}/history?user=${userId}`, {
        headers: { 
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (response.data && Array.isArray(response.data['hydra:member'])) {
          setHistories(response.data['hydra:member']); 
        } else {
          console.error("La structure de la réponse de l'API n'est pas celle attendue:", response.data);
          setHistories([]); 
        }
      })
      .catch(error => {
        console.error("Erreur lors de la récupération de l'historique:", error);
        setHistories([]); 
      });
    }
  }, [user]); 
  const handleFavoriteToggle = async (index) => {
    console.log(index);
    try {
        await axios.delete(`${API_URL}/history/${index}`, {
          headers: { 'Content-Type': 'application/ld+json' },
        });
        notify(`L'article a été supprimé des favoris.`, 'red');
        window.location.reload();
      } catch (error) {
      console.error('Erreur lors de la modification des favoris:', error);
      notify('Une erreur s\'est produite lors de la modification des favoris.', 'red');
    }
  };
  return (
    <CustomMainContainer>
        <CustomGridContainer container spacing={4} padding={5}>
            <Grid item xs={12}>
              <CustomHistoryContainer>
                <CustomTypography variant="h6" textAlign={'center'} gutterBottom>Mes Favoris</CustomTypography>
                {histories ? histories.map((history, index) => (
                  <Box key={index} className={styles.boxHistory}>
                    <Box className={styles.contentWrapper}>
                      <Box className={styles.divLineHistory}>
                        <Box className={styles.dateHistory}>
                          {new Date(history.date).toLocaleDateString('fr-FR', {
                            year: 'numeric', month: '2-digit', day: '2-digit',
                          })}
                        </Box>
                        <Box className={styles.destHistory}>Pour : {history.recipient}</Box>
                      </Box>

                      <Box className={styles.divIdeaDelete}>
                        <Box className={styles.giftHistory}>Idées de cadeau : {history.productName}</Box>
                        <Box className={styles.deleteHistory} onClick={() => handleFavoriteToggle(history.id)}><DeleteIconOutlined fontSize="large" /></Box>
                      </Box>
                    </Box>
                  </Box>
                )): ''}

              </CustomHistoryContainer>
            </Grid>
        </CustomGridContainer>
        {notification && (
        <div className={`notify ${notificationClass}`}>
            {notification}
        </div>
      )}
    </CustomMainContainer>
  );
}

const CustomTypography = styled(Typography)({
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 900,
  fontSize: '40px',
  color: "white",
  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.75)",
});
const CustomMainContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'hidden',
  paddingLeft: theme.spacing(0.375), 
  paddingRight: theme.spacing(0.375),
  backgroundColor: '#FF7396',
}));

const CustomGridContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(10), 
  '@media (max-width: 480px)': {
    padding: theme.spacing(4), 

  },
}));

const CustomHistoryContainer = styled(Box)(({ theme }) => ({
  minHeight:'30vh',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: theme.spacing(4), 
  boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)',
  backgroundColor: '#F7CB46',
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(5), 
}));
export default History;

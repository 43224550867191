import React, { useState, useEffect } from 'react';
import { TextField, Typography, Box, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import styles from './AuthStyles.module.css';
import { useNavigate } from 'react-router-dom';
import { initializeGoogleLogin, handleGoogleSuccess, handleGoogleFailure, handleGoogleLogin } from './GoogleAuth/GoogleLogic';
import { handleFacebookLogin } from './FacebookAuth/FacebookLogic';
import {API_URL} from '../config';
import axios from 'axios';


function AuthForm({ connexion, textButton }) {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [notification, setNotification] = useState('');
  const [notificationClass, setNotificationClass] = useState('');
  const notify = (message, type) => {
    setNotification(message);
    setTimeout(() => setNotification(''), 3000);
    if (type === 'green') {
      setNotificationClass('green-notify');
    } else {
      setNotificationClass('');
    }
  };
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (connexion) {
      navigate('/login');
    } else {
      navigate('/register');
    }
  };

  const handleSignUp = (event) => {
    event.preventDefault();
  
    if (!email || !password) {
      notify('Veuillez remplir tous les champs requis.');
      return;
    }

    const formData = {
      email: email,
      password: password,
    };
  
    if (!connexion) {
      if (!username) {
        notify('Veuillez remplir tous les champs requis.');
        return;
      }
      formData.username = username;
    }

    const config = {
      headers: {
        'Content-Type': 'application/ld+json',
      },
    };
  
    axios.post(connexion ? `${API_URL}/auth` : `${API_URL}/users`, formData, config)
      .then((response) => {
        const data = response.data;
        if (connexion) {
          localStorage.setItem('authToken', data.token);
          navigate('/');
          window.location.reload();
        } else {
          return axios.post(`${API_URL}/auth`, { email, password }, config);
        }
      })
      .then((response) => {
        if (response && response.data) {
          localStorage.setItem('authToken', response.data.token);
          navigate('/');
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error('Erreur réseau lors de la connexion ou de l\'inscription', error);
        notify('Une erreur s\'est produite', 'red');
      });
  
  };
  

  useEffect(() => {
    const cleanupGoogleScript = initializeGoogleLogin(
      (response) => handleGoogleSuccess(response, navigate, notify), // Assurez-vous de passer notify ici
      (error) => handleGoogleFailure(error, notify), // Et ici
      navigate
    );

    return () => {
      cleanupGoogleScript();
    };
  }, [navigate]);

  return (
    <Box className={styles.rightBox}>
      <h4 className={styles.inscriptionHeader}>
        {connexion ? 'Connexion' : 'Inscription'}
      </h4>
      <Box className={styles.socialButtons}>
        <div className={styles.iconButton} aria-label="Facebook" onClick={handleFacebookLogin}>
          <FacebookIcon className={styles.icon} fontSize='large'/>
        </div>
        <div className={styles.iconButton}  aria-label="Google" onClick={handleGoogleLogin}>
          <GoogleIcon className={styles.icon}  fontSize='large'/>
        </div>
        <div className={styles.iconButton}  aria-label="Apple">
          <AppleIcon className={styles.icon}  fontSize='large'/>
        </div>
      </Box>
      <Typography variant="subtitle1" className={styles.emailText} style={{marginTop: connexion ? 30 : 1}}>
        ou avec ton e-mail !
      </Typography>
      <form onSubmit={handleSignUp} className={styles.authForm}>
        {!connexion && ( 
          <TextField
            fullWidth
            placeholder="Pseudo"
            margin="normal"
            value={username}
            autoComplete="username"
            onChange={(e) => setUsername(e.target.value)}
            className={styles.authTextField}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <PersonOutlineIcon />
                </IconButton>
              ),
            }}
          />
        )}
        <TextField
          fullWidth
          placeholder="E-mail"
          autoComplete="email"
          style={{marginBottom: connexion ? 20 : 0, marginTop: 15}}
          type="email"
          margin="normal"

          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={styles.authTextField}
          InputProps={{
            startAdornment: (
              <IconButton>
                <EmailOutlinedIcon />
              </IconButton>
            ),
          }}
        />
        <TextField
          fullWidth
          placeholder="Mot de passe"
          type="password"
          margin="normal"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={styles.authTextField}
          InputProps={{
            startAdornment: (
              <IconButton>
                <LockOutlinedIcon />
              </IconButton>
            ),
          }}
        />
        <button
          className={styles.inscriptionButton}
          style={{ marginTop: connexion ? 65 : 42 }}
          onClick={handleButtonClick}
        >
          {textButton}
        </button>
      </form>
      {notification && (
        <div className={`notify ${notificationClass}`}>
            {notification}
        </div>
    
)}
    </Box>
    
  );
}

export default AuthForm;

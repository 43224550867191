import * as React from 'react';
import { Typography, Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

const TitreSection = styled(Typography)({
  fontSize: '45px',
  fontWeight: 800,
  fontFamily: 'Poppins, sans-serif',
  color: 'white',
  textAlign: 'center',
  textShadow: '0 3px 0 black',
  mb: 6
});

const StyledBox = styled(Box)({
  flexGrow: 1,
  overflow: 'hidden',
  backgroundColor: '#F7CB46',
});

const StyledContainer = styled(Container)({
  display: 'flex', 

  marginTop: '100px',
  marginBottom: '0px',
  '@media (max-width: 480px)': {
    justifyContent:'center',
    marginTop: '60px',
    marginBottom: '20px',
  },
  
});
const GiftoImg = styled('img')({
  filter: 'drop-shadow(-10px 10px 0px rgba(0, 0, 0, 0.25))',

  '@media (max-width: 480px)': {
    display:'none'
  },
});



const StyledContainerFirstBox = styled(Box)({
  flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center',  
});

const StyledContainerSecondBox = styled(Box)({
  flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 99,
});

const StyledFirstTransition = styled(Box)({
  position: 'absolute',
  overflow: 'hidden',
  width: '100%',
  height: '200px',
  backgroundColor: '#FF7396',
  backgroundImage: `url('assets/yellow-wave.svg')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  
});

const StyleBoxAfterFirstTransition = styled(Box)({
  display:'flex',
  alignItems:'center',
  width:'100%',
  
  backgroundColor: '#FF7396', 
  padding:'20% 10% 0% 10%',
  '@media (max-width: 480px)': {
    padding:'8% 4% 0% 4%',
    flexDirection:'column',
  },
});

const FirstExtendTitreSection = styled(TitreSection)(({ textAlign }) => ({  
  fontSize: '45px', fontWeight: 800, fontFamily: 'Poppins, sans-serif', color: 'white', textAlign: textAlign || 'end', marginBottom: '40px',
  '@media (max-width: 480px)': {
    position:'relative',
    textAlign:'center',
  },
}));

const DovanaTitle = styled(Typography)({
  color: '#FF7396', fontWeight: 1000, fontFamily: 'Poppins, sans-serif', fontSize: '65px', letterSpacing: '0.03em',
  textShadow: 
  `-3px -3px 0 #000,
    3px -3px 0 #000,
    -3px  3px 0 #000,
    3px  3px 0 #000,  
    -6px 8px 0 #000`,
    '@media (max-width: 480px)': {
      textAlign:'center',
      fontSize: '12vw'
    },
    
});

const GiftTypo = styled(Typography)({
  width:'100%',
  fontWeight: 800, marginBottom: '20px', marginTop:'25px',
  '@media (max-width: 480px)': {
    textAlign:'center',
    fontSize: '6vw'
  },
  
});

const DescGiftTypo = styled(Typography)({
  width:'100%', fontWeight: 500,
  '@media (max-width: 480px)': {
    textAlign:'justify',
    fontSize: '4vw',
    paddingRight: '0'
  },
});
const GiftoInfo = styled('div')({
  textAlign:'flex-start',
  width:'50%',
  '@media (max-width: 480px)': {
    textAlign:'center',
    width:'100%',
    padding: '4%',
  },
});

const GiftoImgLogo = styled('img')({
  width:'400px',
  height:'400px',
  '@media (max-width: 480px)': {
    margin: '100px 0 40px 0',
    width:'200px',
    height:'200px',
  },
});

const AffiliateContainer = styled(Container)({
  paddingTop:'260px',
  '@media (max-width: 480px)': {
    paddingTop:'125px',
  },
});
const AmazonImg = styled('img')({
  '@media (max-width: 480px)': {
    width:'100%',
    marginBottom:'80px'
  },
});

const StyledTransitionPink = styled(Box)({
  position: 'absolute',
  overflow: 'hidden',
  width: '100%',
  height: '300px',
  backgroundColor: '#F7CB46',
  backgroundImage: `url('assets/pink-wave.svg')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

const StyledTransitionYellow = styled(Box)({  
  position: 'absolute',
  overflow: 'hidden',
  width: '100%',
  height: '200px',
  backgroundColor: '#FF7396',
  backgroundImage: `url('assets/yellow-wave.svg')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

const FlexGifto = styled(Box)({  
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  flexDirection:'row',

});

const HowContainer = styled(Box)({  
  display:'flex',
  flexDirection:'column',
  alignItems:'flex-end',
  justifyContent:'center',
  '@media (max-width: 480px)': {
    alignItems:'center',
  },
 
});

const GridTypo = styled(Box)(({ textAlign, alignItems, width }) => ({  
  display:'flex',
  flexDirection:'column',
  alignItems: alignItems || 'flex-end',
  justifyContent:'center',
  textAlign:textAlign || 'end', 
  color:'white',
  gap:'25px',
  width:width || '85%',
  '@media (max-width: 480px)': {
    textAlign:'justify',
  },
}));

const LastGridTypo = styled(GridTypo)({  
  '@media (max-width: 480px)': {
    padding: '4%',
  },
 });
 



const FirstGridTypo = styled(Typography)(({ color }) => ({ 
 fontSize:'20px',
 color: color || 'white',
 fontWeight:'500',
 '@media (max-width: 480px)': {
  zIndex:2,
},
}));

const SecondGridTypo = styled(Typography)({  
 fontSize:'15px',
 fontStyle:'italic'

});

const IdealBox = styled(Box)({  
  paddingTop: '300px', backgroundColor: '#FF7396',
  '@media (max-width: 480px)': {
    paddingTop: '150px', 
   
  },
 });
 
 const BoxIframe = styled(Box)({
  width:'100%',  
  height:'400px',

  '@media (max-width: 480px)': {
    width:'100%',
    height:'250px'

   
  },
 });
 const CustomIframe = styled('iframe')({  
    width:'50%',  
    height:'100%',

    '@media (max-width: 480px)': {
      width:'100%',
      height:'100%'
    
    },
 });
 

function LearnMore() {

  return (
    <StyledBox>
        <StyledContainer maxWidth="lg">
          <StyledContainerFirstBox>
            <DovanaTitle id="dovanaTitle" variant="h2" gutterBottom>Comment fonctionne Dovana ?</DovanaTitle>
            <FlexGifto>
              <GiftoInfo>
                <GiftTypo variant="h4">Dovana, c'est quoi ?</GiftTypo>
                <DescGiftTypo variant="h5" gutterBottom>Besoin d'idées cadeaux ? Pas de souci, Dovana est là pour vous ! Grâce à l'utilisation de l'IA, notre site peut trouver le cadeau idéal en fonction des caractéristiques et des goûts d’une personne.</DescGiftTypo>
              </GiftoInfo>
              <StyledContainerSecondBox>
                <GiftoImg src="/images/gifto.png" alt="Logo Dovana" width="300" height="300" />
              </StyledContainerSecondBox>
            </FlexGifto>
          
          </StyledContainerFirstBox>
         
        </StyledContainer>
        {/* Transition 1 */}
        <StyledFirstTransition/>
        <StyleBoxAfterFirstTransition>
          <StyledContainerSecondBox>
            <GiftoImgLogo src="/images/logo.png" alt="Logo Dovana"/>
          </StyledContainerSecondBox>
          <HowContainer>
            <FirstExtendTitreSection variant="h4">Comment fonctionne Dovana</FirstExtendTitreSection>
            <GridTypo>
              <FirstGridTypo>Dovana utilise l'IA de ChatGPT pour vous aider à trouver le cadeau idéal. En répondant à différentes questions telles que le prénom, le sexe, l'âge, l'événement, le lien avec la personne, les centres d'intérêt, le type de cadeau et le budget, l'IA sera en mesure de définir le cadeau parfait pour la personne concernée. Ensuite, elle recherchera ce cadeau sur le site Amazon.</FirstGridTypo>
              <SecondGridTypo>Les informations que vous entrez sur le site ne seront pas partagées. Elles seront uniquement utilisées dans le but d'améliorer le site.</SecondGridTypo>

            </GridTypo>
          </HowContainer>
        </StyleBoxAfterFirstTransition>
        {/* Transition 2 */}
        <StyledTransitionPink/>
        <AffiliateContainer maxWidth="lg">
        
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection:'column', textAlign:'center', justifyContent: 'center'}}>
            <GiftTypo variant="h4" style={{zIndex:10}}>Affiliation à Amazon</GiftTypo>
            <GridTypo textAlign={'center'} alignItems={'center'}>
              <FirstGridTypo color='black'>Dovana utilise l'IA de ChatGPT pour vous aider à trouver le cadeau idéal. En répondant à différentes questions telles que le prénom, le sexe, l'âge, l'événement, le lien avec la personne, les centres d'intérêt, le type de cadeau et le budget, l'IA sera en mesure de définir le cadeau parfait pour la personne concernée. Ensuite, elle recherchera ce cadeau sur le site Amazon.</FirstGridTypo>
              <Box sx={{ my: 5, mb:-4, zIndex:10, display:'flex', justifyContent:'center' }}>
                <AmazonImg src='/assets/Amazon_logo.svg' alt="logo amazon"/>
              </Box>
            </GridTypo>

          </Box>
        </AffiliateContainer>

        {/* Transition 3 */}
        <StyledTransitionYellow/>
        <IdealBox>
          <FirstExtendTitreSection textAlign={'center'} variant="h4">Comment trouver le cadeau idéal ?</FirstExtendTitreSection>
            <LastGridTypo textAlign={'center'}  width={'100%'} alignItems={'center'}>
              <FirstGridTypo color='white'>Grâce à cette vidéo, vous allez pouvoir comprendre comment faire pour générer le cadeau idéal</FirstGridTypo>
              <BoxIframe>
                <CustomIframe
                  
                  src="https://www.youtube.com/embed/f5Yuh37rYlw"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </BoxIframe>
            </LastGridTypo>
        </IdealBox>
        <StyledTransitionPink/>
        <Box sx={{ pt: 35, pb: 5 }}>
         
        </Box>

    </StyledBox>
  );
}

export default LearnMore;
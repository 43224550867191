import {jwtDecode} from 'jwt-decode';

export const API_URL = 'https://api-dovana.dorianbidault.fr';
export const FRONT_URL = 'https://dovana.dorianbidault.fr';

export function decodeToken(token) {
    try {
      const decoded = jwtDecode(token);
      return decoded;
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  }


import React, {useState, useEffect} from 'react';
import { Grid, Box, Avatar, Typography } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';
import styles from './Forum.module.css';
import { API_URL } from '../config.js';
import { useNavigate } from 'react-router';

function Forum() {
  const [user, setUser] = useState(null);
  const [forumPosts, setForumPosts] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      const decoded = jwtDecode(authToken);
      const userId = decoded['id'];
      const config = {
        headers: {
          'Content-Type': 'application/ld+json',
          'Authorization': `Bearer ${authToken}`
        }
      };

      axios.get(`${API_URL}/users/${userId}`, config)
        .then(response => setUser(response.data))
        .catch(error => console.error('Error fetching user data:', error));
      
      axios.get(`${API_URL}/forum_posts`, config)
        .then(response => setForumPosts(response.data))
        .catch(error => console.error('Error fetching forum posts:', error));
    }
  }, []);


  const handleModify = () => {
    navigate('/post');
  }
  return (
    <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }}>
        <Grid container spacing={4} padding={5}>
          <Grid item xs={12} sm={4} md={3}>
            <Box sx={{ display: 'flex', height: 600, flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', gap: 2, boxShadow: '4px 4px 4px 0 rgba(0, 0, 0, 0.25)', backgroundColor: '#F7F7F7', marginBottom: 5, borderRadius: 5, padding: 4 }}>
                <Avatar sx={{ width: 100, height: 100 }} >
                    {!user?.avatarUrl && <AccountCircle sx={{ width: 100, height: 100 }} />}
                </Avatar>
                <Typography variant="h6" gutterBottom style={{ fontSize: '24px', fontWeight: '600' }}>
                    {user ? `${user.firstName} ${user.lastName}` : 'Chargement...'}
                </Typography>
                <Typography variant="h6" gutterBottom style={{ fontSize: '24px', fontWeight: '600' }}>
                Bio
                </Typography>
                <Typography variant="p" gutterBottom style={{ fontSize: '16px', fontWeight: '300' }}>
                {user?.bio ? `${user?.bio}` : 'Aucune biographie'}
                </Typography>
                <button className={styles.profileButton} style={{width:'150px', height:'45px', borderRadius: '25px', fontSize:'24px', fontWeight:'600', fontFamily:'Poppins, sans-serif', marginBottom:5}}>Modifier</button>
                <div className={styles.createPostButton} style={{ fontWeight: '600', fontFamily: 'Poppins, sans-serif', marginBottom: 5, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <p style={{textAlign:'start', width:'100%', marginLeft:20}}>Crée un post</p>
                <div style={{ width: '55px', height: '55px', marginRight:-4,display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55">
                    <circle cx="27.5" cy="27.5" r="24" fill="#F7CB46" stroke="black" strokeWidth="2" />
                    <text x="50%" y="50%" textAnchor="middle" dy=".3em" fontSize="27" fill="black">+</text>
                    </svg>
                </div>
                </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
          <Box
            sx={{
                display: 'flex',
                height: 100,
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 2,
                boxShadow: '4px 4px 4px 0 rgba(0, 0, 0, 0.25)',
                backgroundColor: '#F7F7F7',
                marginBottom: 5,
                borderRadius: 5,
                padding: 4,
            }}
            >
            <div style={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
                <input
                type="text"
                placeholder="Rechercher..."
                style={{
                    flex: 1,
                    height: '55px',
                    border: '2px solid black',
                    borderRadius: '10px 0 0 10px',
                    padding: '0 10px 0 15px',
                    fontSize: 16,
                }}
                />
                <div
                style={{
                    width: '55px',
                    height: '55px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    border: '2px solid black',
                    borderRadius: '0 10px 10px 0',
                    backgroundColor: '#F7CB46',
                }}
                >
                <SearchIcon style={{ fontSize: 24, color: 'black' }} />
                </div>
            </div>
            </Box>
            {forumPosts['hydra:member'] && forumPosts['hydra:member'].slice(0, 3).map((post, index) => (

            <Box sx={{ display: 'flex', height: 150, alignItems: 'center', justifyContent: 'flex-start', gap: 2, boxShadow: '4px 4px 4px 0 rgba(0, 0, 0, 0.25)', backgroundColor: '#F7F7F7', marginBottom: 5, borderRadius: 5, padding: 4 }}>
                <Avatar sx={{ width: 100, height: 100, marginRight:5}} src={post.userAvatarUrl || undefined}>
                    {!post.userAvatarUrl && <AccountCircle sx={{ width: 100, height: 100 }} />}
                </Avatar>
                <Box sx={{ display: 'flex', width:'100%', flexDirection:'column', paddingRight:5}}>
                    <Typography variant="h6" gutterBottom style={{ fontSize: '24px', fontWeight: '600' }}>
                    {post.subject}
                    </Typography>
                    <Typography variant="p" gutterBottom style={{ fontSize: '16px', fontWeight: '300' }}>
                    {post.description}
                    </Typography>
                </Box>
                <button className={styles.profileButton} onClick={handleModify} style={{width:'150px', height:'45px', borderRadius: '25px', fontSize:'24px', fontWeight:'600', fontFamily:'Poppins, sans-serif', marginRight:10}}>Modifier</button>

            </Box>
            ))}
           
          </Grid>
        </Grid>
    </Box>
  );
}

export default Forum;

import React, { useEffect, useState } from 'react';
import {jwtDecode} from 'jwt-decode'; 
import { Grid, Box } from '@mui/material';
import LeftAvatar from './Left/LeftAvatar';
import LeftBio from './Left/LeftBio';
import RightInfos from './Right/RightInfos';
import { API_URL } from '../config';
import axios from 'axios';
import { styled } from '@mui/system';

function Profile() {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      const decoded = jwtDecode(token);
      const userId = decoded.id;
      console.log(decoded);
      axios.get(`${API_URL}/users/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        setUserData(response.data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
    }
  }, []);

  return (
    <StyledBox>
        <Grid container spacing={4} padding={5}>
          <Grid item xs={12} sm={4} md={3}>
            <LeftAvatar avatarUrl={userData?.avatarUrl} />
            <LeftBio/>
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            <RightInfos userData={userData} />
          </Grid>
        </Grid>
    </StyledBox>
  );
}
const StyledBox = styled(Box)({
  flexGrow: 1, overflow: 'hidden', px: 3, backgroundColor:'#F7CB46'
});
export default Profile;

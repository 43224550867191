import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
document.addEventListener('scroll', function() {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const scrollHeight = document.documentElement.scrollHeight;
  const clientHeight = document.documentElement.clientHeight;
  const atBottom = scrollHeight - Math.ceil(scrollTop + clientHeight) <= 1;
  const atTop = scrollTop <= 1;

  if (atBottom) {
    document.documentElement.style.setProperty('--scrollbar-thumb-border-radius-bottom', '0');
  } else {
    document.documentElement.style.setProperty('--scrollbar-thumb-border-radius-bottom', '8px');
  }

  if (atTop) {
    document.documentElement.style.setProperty('--scrollbar-thumb-border-radius-top', '0');
  } else {
    document.documentElement.style.setProperty('--scrollbar-thumb-border-radius-top', '8px');
  }
});

reportWebVitals();

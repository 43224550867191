import React, { useState, useEffect } from 'react';
import { Box as MuiBox } from '@mui/material';
import { styled } from '@mui/system';
import { useFormData } from '../../provider/FormDataProvider'; // Assurez-vous que le chemin d'accès est correct

function BI_4({ setSaveCurrentFormData }) {
  const { formData, updateFormData } = useFormData();
  const [selectedOption, setSelectedOption] = useState(formData.event || '');

  useEffect(() => {
    setSaveCurrentFormData(() => () => {
      updateFormData({ event: selectedOption });
    });
  }, [selectedOption, setSaveCurrentFormData, updateFormData]);

  const handleButtonClick = (option) => {
    setSelectedOption(prevOption => prevOption === option ? '' : option);
  };

  return (
    <ContentBox>
      <ButtonBox>
        <PromptButton
          widthMobile='47%'
          heightMobile='10vw'
          fontSizeMobile='4vw'
          className={selectedOption === 'St Valentin' ? 'selected' : 'profileButton'}
          onClick={() => handleButtonClick('St Valentin')}
        >
          St Valentin
        </PromptButton>
        <PromptButton
          widthMobile='47%'
          heightMobile='10vw'
          fontSizeMobile='4vw'
          className={selectedOption === 'Noël' ? 'selected' : 'profileButton'}
          onClick={() => handleButtonClick('Noël')}
        >
          Noël
        </PromptButton>
        <PromptButton
          widthMobile='47%'
          heightMobile='10vw'
          fontSizeMobile='4vw'
          className={selectedOption === 'Anniversaire' ? 'selected' : 'profileButton'}
          onClick={() => handleButtonClick('Anniversaire')}
        >
          Anniversaire
        </PromptButton>
        <PromptButton
          widthMobile='47%'
          heightMobile='10vw'
          fontSizeMobile='4vw'
          className={selectedOption === "Fête d'un Parent" ? 'selected' : 'profileButton'}
          onClick={() => handleButtonClick("Fête d'un Parent")}
        >
          Fête d'un Parent
        </PromptButton>
      </ButtonBox>
      <ButtonBox>
        <PromptButton
          widthMobile='47%'
          heightMobile='10vw'
          fontSizeMobile='4vw'
          className={selectedOption === 'Simple cadeau' ? 'selected' : 'profileButton'}
          onClick={() => handleButtonClick('Simple cadeau')}
        >
          Simple cadeau
        </PromptButton>
        <PromptButton
          widthMobile='47%'
          heightMobile='10vw'
          fontSizeMobile='4vw'
          className={selectedOption === 'Mariage' ? 'selected' : 'profileButton'}
          onClick={() => handleButtonClick('Mariage')}
        >
          Mariage
        </PromptButton>
        <PromptButton
          widthMobile='47%'
          heightMobile='10vw'
          fontSizeMobile='4vw'
          className={selectedOption === 'Crémaillère' ? 'selected' : 'profileButton'}
          onClick={() => handleButtonClick('Crémaillère')}
        >
          Crémaillère
        </PromptButton>
        <PromptButton
          widthMobile='47%'
          heightMobile='10vw'
          fontSizeMobile='4vw'
          className={selectedOption === 'Secret Santa' ? 'selected' : 'profileButton'}
          onClick={() => handleButtonClick('Secret Santa')}
        >
          Secret Santa
        </PromptButton>
      </ButtonBox>
    </ContentBox>
  );
}

const PromptButton = styled('button')(({ theme, width, widthMobile, heightMobile, fontSizeMobile}) => ({
  boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)',
  width: width || '162px',
  height: '36px',
  fontSize: '15px',
  fontWeight: '600',
  '&.selected': {
    backgroundColor: '#FF7396', 
    borderRadius:'15px',
    color:"white",
  },
  "&:hover":{
    backgroundColor:'#FF7396'
  },
  '@media (max-width: 480px)': {
    width: widthMobile ? widthMobile : '47%',
    height: heightMobile ? heightMobile : '40%',
    fontSize: fontSizeMobile ? fontSizeMobile : '20px',

  },
}));

const ContentBox = styled(MuiBox)({
  height: '100%', 
  width: '100%', 
  backgroundColor: '#F7CB46', 
  display: 'flex', 
  flexDirection:'column',
  alignItems: 'center', 
  paddingTop:'5%',
  justifyContent: 'flex-start',
  gap:'10px', 
  position: 'relative',
  '@media (max-width: 480px)': {
    paddingTop:'5%',
    gap:'2vw'
   
  },
});

const ButtonBox = styled(MuiBox)(({ theme, widthMobile, heightMobile, fontSizeMobile}) => ({
  width: '50%', 
  height: '20%', 
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'space-evenly',
  flexWrap:'wrap',
  gap:'20px',
  zIndex:20,
  '@media (max-width: 480px)': {
    width: widthMobile ? widthMobile : '90%',
    height: heightMobile ? heightMobile : '35%',
    gap:'2vw',
    
  },

}));


export default BI_4;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { decodeToken } from '../../config';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './Header.module.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Button } from '@mui/material';
import { styled } from '@mui/system';

function ProfilHeader() {
  const [username, setUsername] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const theme = createTheme({
    typography: {
        fontFamily: 'Poppins, Arial, sans-serif',
      },
    components: {
      MuiMenu: {
        styleOverrides: {
          paper: {
            width: '200px',
            
          },
        },
      },
    },
  });

  const handleMenuToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  
  const handleProfile = () => {
    handleMenuClose();
    navigate('/profile');
  };

  const handleHistory = () => {
    handleMenuClose();
    navigate('/history');
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    handleMenuClose();
    navigate('/login', { replace: true });
    window.location.reload();
  };
  
  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      const decodedToken = decodeToken(authToken);
      if (decodedToken && decodedToken.username) {
        setUsername(decodedToken.username);
      }
    } else {
      setUsername(null);
    }
  }, [username]); 

  return (
    <>
    <ThemeProvider theme={theme}>
      <StyledButton color="inherit" onClick={handleMenuToggle}>Mon Compte</StyledButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}
        PaperProps={{
          sx: {
            bgcolor: '#FF7396', 
            color: 'white'
          }
        }}
      >
        <MenuItem onClick={handleProfile}>Profil</MenuItem>
        <MenuItem onClick={handleHistory}>Historique</MenuItem>
        <MenuItem onClick={handleLogout}>Déconnexion</MenuItem>
      </Menu>
      </ThemeProvider>
    </>
  );
}
const StyledButton = styled(Button)({
  textTransform: 'none',
  fontSize: '16px',
});
export default ProfilHeader;

import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useFormData } from '../../provider/FormDataProvider';
import DropdownMenu from '../../components/PromptSelect/DropdownMenu';
import DropdownMenuLarge from '../../components/PromptSelect/DropdownMenuLarge';
import useMediaQuery from '@mui/material/useMediaQuery';

function BI_3({ setSaveCurrentFormData }) {
  const { formData, updateFormData } = useFormData();
  const isMobile = useMediaQuery('(max-width:480px)');  

  const [selected, setSelected] = useState(formData.relationshipCategory || {});

  useEffect(() => {
    setSaveCurrentFormData(() => () => {
      updateFormData({ relationshipCategory: selected });
    });
  }, [selected, setSaveCurrentFormData, updateFormData]);

  const handleSelection = (selectedCategory, value) => {
    const newSelection = {
        'family': null,
        'relative': null,
        'externe': null,
        'partner': null,
        [selectedCategory]: value 
    };
    setSelected(newSelection);
};
console.log(selected);
  const handleCategoryClick = () => {
    handleSelection('partner', 'Partenaire');
  };
  const familyOptions = [
    { label: 'Père/Mère', value: 'pere' },
    { label: 'Grand-Père/Mère', value: 'grandpere' },
    { label: 'Fils/Fille', value: 'fils' },
    { label: 'Petit(e)-Fils/Fille', value: 'petitfils' },
    { label: 'Frère/Soeur', value: 'frere' },
  ];
  const familyMobileOptions = [
    { label: 'Père/Mère', value: 'pere' },
    { label: 'Fils/Fille', value: 'fils' },
    { label: 'Frère/Soeur', value: 'frere' },
  ];
  const relativeOptions = [
    { label: 'Pote', value: 'pote' },
    { label: 'Ami(e)s', value: 'ami' },
    { label: 'Collègue', value: 'collegue' },
  ];
  const externeOptions = [
    { label: 'Voisin(e)', value: 'voisin' },
    { label: 'Patron(ne)', value: 'patron' },
    { label: 'Collègue de Travail', value: 'coworker' },
  ];
  
  return (
    <ContentBox>
      <ButtonBox>
        <PromptButton
          className={selected['partner'] === 'Partenaire' ? 'selected' : 'profileButton'}
          onClick={handleCategoryClick}
        >
          Partenaire
        </PromptButton>
        
        <DropdownMenu 
          key={`relative-${selected['relative']}`}
          options={relativeOptions} 
          placeholder='Proche' 
          onSelect={(value) => handleSelection('relative', value)} 
          selectedValue={selected['relative']}
          bgValueColor={selected['relative'] ? '#FF7396' : 'white'}
          colorValue={selected['relative'] ? 'white' : 'black'}
          zIndexOptions={15}
          zIndexSelect={20}
        />
        <DropdownMenu 
          key={`externe-${selected['externe']}`}
          options={externeOptions} 
          placeholder='Externe' 
          onSelect={(value) => handleSelection('externe', value)} 
          selectedValue={selected['externe']}
          bgValueColor={selected['externe'] ? '#FF7396' : 'white'}
          colorValue={selected['externe'] ? 'white' : 'black'}
        />
        {isMobile ? 
         <DropdownMenu
          key={`family-${selected['family']}`}
          options={familyMobileOptions} 
          placeholder='Famille' 
          onSelect={(value) => handleSelection('family', value)} 
          selectedValue={selected['family']}
          bgValueColor={selected['family'] ? '#FF7396' : 'white'}
          colorValue={selected['family'] ? 'white' : 'black'}
        /> : 
        <DropdownMenuLarge
          key={`family-${selected['family']}`}
          options={familyOptions} 
          placeholder='Famille' 
          onSelect={(value) => handleSelection('family', value)} 
          selectedValue={selected['family']}
          bgValueColor={selected['family'] ? '#FF7396' : 'white'}
          colorValue={selected['family'] ? 'white' : 'black'}
        />

        }
       
        </ButtonBox>
    </ContentBox>
  );
}

const PromptButton = styled('button')(({ theme, widthMobile, heightMobile, fontSizeMobile}) => ({
  boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)',
  width: '225px',
  height: '36px',
  fontSize: '15px',
  fontWeight: '600',
  '&.selected': {
    backgroundColor: '#FF7396', 
    borderRadius:'15px',
    color:"white",
    
  },
  "&:hover":{
    backgroundColor:'#FF7396'
  },
  '@media (max-width: 480px)': {
    width: widthMobile ? widthMobile : '47%',
    height: heightMobile ? heightMobile : '10vw',
    fontSize: fontSizeMobile ? fontSizeMobile : '5vw',

  }
}));



const ContentBox = styled(Box)(({ theme, widthMobile, heightMobile, fontSizeMobile}) => ({
  height: '100%',
  width: '100%',
  backgroundColor: '#F7CB46',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '3%',
  justifyContent: 'flex-start',
  gap: '10px',
  position: 'relative',
  '@media (max-width: 480px)': {
    paddingTop:'50px',


  }
}));

const ButtonBox = styled(Box)(({ theme, widthMobile, heightMobile, fontSizeMobile}) => ({
  width: '70%',
  height: '20%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  flexWrap:'wrap',
  gap:'20px',
  zIndex:20,
  '@media (max-width: 480px)': {
    width: widthMobile ? widthMobile : '90%',
    height: heightMobile ? heightMobile : '30%',
    fontSize: fontSizeMobile ? fontSizeMobile : '20px',
    gap:'5vw',

  }
}));



export default BI_3;

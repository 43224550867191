import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Box, Checkbox, Typography } from '@mui/material';
import CarouselComponent from '../../components/Carousel/CarouselComponent';
import { useFormData } from '../../provider/FormDataProvider';
import useMediaQuery from '@mui/material/useMediaQuery';

function BI_5({ setSaveCurrentFormData }) {
  const { formData, updateFormData } = useFormData();
  const isMobile = useMediaQuery('(max-width:480px)');  

  const initialCheckedItems = formData.categories || Object.fromEntries(categories.map(category => [category, false]));
  const [checkedItems, setCheckedItems] = useState(initialCheckedItems);

  const handleCheckboxChange = (category) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [category]: !prevCheckedItems[category]
    }));
  };

  useEffect(() => {
    const saveCurrentFormData = () => {
      setSaveCurrentFormData(() => () => {
        const filteredCategories = Object.entries(checkedItems).reduce((acc, [category, isSelected]) => {
          if (isSelected) acc[category] = isSelected;
          return acc;
        }, {});
        updateFormData({ categories: filteredCategories });
      });
    };

    saveCurrentFormData();
  }, [checkedItems, setSaveCurrentFormData, updateFormData]);

  return (
    <ContentBox>
      <CarouselComponent itemsToShow={4}>
        {categories.map((category, index) => (
          <ChooseDiv key={index} style={{ backgroundColor: checkedItems[category] ? 'rgba(255, 115, 150, 1)' : 'white' }} onClick={() => handleCheckboxChange(category)}>
            <CustomIconBox>{icons[index]}</CustomIconBox>
            <CustomTypography style={{ color: checkedItems[category] ? 'white' : 'black' }} variant="subtitle1">
              {category}
            </CustomTypography>
            {!isMobile ?
              <Checkbox checked={checkedItems[category]} style={{ color: checkedItems[category] ? 'white' : 'black' }} onClick={(event) => event.stopPropagation()}/>
              : ''
            }
          </ChooseDiv>
        ))}
      </CarouselComponent>
    </ContentBox>
  );
}
const categories = [
  "Technologies et gadgets",
  "Nourriture et boissons",
  "Cinéma",
  "Musique",
  "Vêtements et accessoires",
  "Beauté",
  "Sport et santé",
  "Animaux domestiques",
  "Lecture",
  "Décorations",
  "Jeux et puzzles",
  "En plein air",
  "Art et Design",
  "Jeux vidéo",
  "Culture",
  "Sciences"
];

const icons = [
  "📱",
  "🍔🍹",
  "🎥",
  "🎵",
  "👕",
  "💄",
  "🏈",
  "🐾",
  "📚",
  "🏡",
  "🎲",
  "🏔️",
  "🎨",
  "🎮",
  "🐵",
  "🧬"
];

const ChooseDiv = styled(Box)({
  display:'flex',
  flexDirection:'column',
  alignItems:'center',
  justifyContent:'space-between',
  textAlign:'center',
  width: '20%',
  padding: '1%',
  height: 'auto',
  textTransform: 'none',
  border: '2px solid',
  fontWeight: '700',
  borderRadius: '35px',
  boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)',
  fontSize: '15px',
  backgroundColor: 'white',
  cursor:'pointer',
  '@media (max-width: 480px)': {
    width: '45%',
    height: '16vh',
    justifyContent:'space-evenly',
  },
});

const CustomIconBox = styled(Box)({
  width:'100%',
  fontSize:'40px',
  userSelect: 'none',
  '@media (max-width: 480px)': {
    fontSize:'7vw'
  
  },
});

const CustomTypography = styled(Typography)({
  fontSize:'15px',
  fontWeight:'600',
  '@media (max-width: 480px)': {
    fontSize:'3vw'
  
  },
});

const ContentBox = styled(Box)({
  height: '100%', 
  width: '100%', 
  backgroundColor: '#F7CB46', 
  display: 'flex', 
  flexDirection:'column',
  alignItems: 'center', 
  paddingTop:'3%',
  justifyContent: 'flex-start',
  gap:'10px', 
  position: 'relative',
});

export default BI_5;

import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import styles from './Footer.module.css';

function Footer() {
  return (
    <StyledAppBar position="static">
      <StyledToolbar>
        <div className={styles.logo}>
            <img src='/images/logo.png' alt="Logo" width="50%"/>
        </div>
        <div className={styles.line1}>
          <img src="/images/Xlogo.png" alt="X (Twitter) Logo"/>
          <hr/>
          <img src="/images/instagram.png" alt="Instagram Logo"/>
          <hr/>
          <img src="/images/tiktok.png" alt="Tiktok Logo"/>
        </div>
        <div className={styles.content}>
          <div className={styles.divAbout}>
            <ListMenu>A PROPOS</ListMenu>
            <ul class={styles.ulAbout}>
              <li>Qui sommes-nous</li>
              <li>Comment ça fonctionne</li>
              <li>L'utilisation de L'IA</li>
              <li>Partenariats</li>
            </ul>
          </div>
          <div className={styles.divLegal}>
            <ListMenu>LEGAL</ListMenu>
            <ul class={styles.ulLegal}>
              <li>Mentions Légales</li>
              <li>Politique de confidentialité</li>
              <li>Conditions d'utilisation</li>
              <li>CGV</li>
            </ul>
          </div>
        </div>
        <div className={styles.line2}>
            <div class={styles.line2menu}>
              <div class={styles.divLineBtn}>
                <MenuButton>Accueil</MenuButton>
              </div>
              <div class={styles.divLineBtn}>
                <MenuButton>Idée Cadeau</MenuButton>
              </div>
              <div class={styles.divLineBtn}>
                <MenuButton>Historique</MenuButton>
              </div>
              <div class={styles.divLineBtn}>
                <MenuButton>Plan du site</MenuButton>
              </div>
            </div>
        </div>
      </StyledToolbar>
    </StyledAppBar>
  );
}


const StyledAppBar = styled(Box)({
  width:'100%',
  backgroundColor: '#FF7396',
  height: 'auto',
  zIndex: 1000,
  overflow: 'hidden',
  position:'relative',
  boxShadow: '0px -5px 15px -5px rgba(0, 0, 0, 0.2)',
  
});

const StyledToolbar = styled(Box)({
  width:'100%',
  display:'flex',
  height:'40vh',
  justifyContent: 'space-between',
  alignItems:'center',
  '@media (max-width: 480px)': {
    flexDirection: 'column',
    height:'170vh',
    justifyContent: 'flex-start',
  },
  
});
const MenuButton = styled('button')(({ width, height }) => ({
  boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)',
  backgroundColor:'white',
  borderRadius:'15px',
  width: width || '162px',
  height: height || '36px',
  fontSize: '15px',
  fontWeight: '600',
  cursor: 'pointer',
  transition: 'all 0.3s ease', 
  "&:hover": {
      backgroundColor: '#FF7396',
  },
  "&:active": {
      boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.5)',
      transform: 'translateY(2px)',
  },
  '@media (max-width: 480px)': {
    fontSize: '4vw',
    width:'90%',
    height: '9vw'
  },
}));
const ListMenu = styled('div')({
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  backgroundColor:'#F7CB46',
  borderRadius:'15px',
  color:'black',
  width: '200px',
  height: '40px',
  fontSize: '20px',
  fontWeight: '700',
  '@media (max-width: 480px)': {
    width:'75vw',
    height: '10vw',
    fontSize: '6vw',
  },
});
export default Footer;

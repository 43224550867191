import React, { useState, useEffect, useRef } from 'react';
import Flag from 'react-world-flags';
import countries from './country-FR.json';
import './CountrySelector.css';

function CountrySelector({ data, setPays }) {
  const [selectedCountry, setSelectedCountry] = useState('');

  const [isListVisible, setIsListVisible] = useState(false);
  const selectorRef = useRef(null);

  const handleSelectCountry = (code) => {
    setSelectedCountry(code);
    setIsListVisible(false);
    setPays(countries[code]);
  };

  const toggleListVisibility = () => {
    setIsListVisible(!isListVisible);
  };

  const listRef = useRef(null); 

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target) &&
          listRef.current && !listRef.current.contains(event.target)) {
        setIsListVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className="selector" onClick={toggleListVisibility} ref={selectorRef}>
        {selectedCountry || data ? (
          <>
            <Flag code={selectedCountry ? selectedCountry : data} height={24} />
            <p style={{marginLeft:10}}>{countries[selectedCountry] ? countries[selectedCountry] : countries[data]}</p>
          </>
        ) : (
          <p style={{color:'gray'}}>Sélectionnez un pays</p>
        )}
      </div>
      <div className={isListVisible ? "countryList visible" : "countryList"} ref={listRef}>
        {Object.keys(countries).map((code) => {
          let itemClass = "countryItem";
          if (code === selectedCountry) {
            itemClass += " selectedCountryItem";
          }
          return (
            <div key={code} className={itemClass} onClick={() => handleSelectCountry(code)}>
              <Flag code={code} height={15} /> <p style={{margin:0, marginLeft:10}}>{countries[code]}</p>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default CountrySelector;

import React, {useState, useEffect} from 'react';
import { Typography, Box} from '@mui/material';
import { styled } from '@mui/system';
import CountrySelector from '../../components/Country/CountrySelector'; // Assurez-vous d'importer correctement le chemin du composant
import countries from '../../components/Country/country-FR.json';
import axios from 'axios';
import { API_URL } from '../../config';
import styles from './RightInfos.module.css'
import Password from '../Password/Password';

function RightInfos({userData}) {
    const [email, setEmail] = useState('');
    const [prenom, setPrenom] = useState('');
    const [nom, setNom] = useState('');
    const [pseudo, setPseudo] = useState('');
    const [pays, setPays] = useState('');
    const [codePostal, setCodePostal] = useState('');
    const [ville, setVille] = useState('');
    const [adresse, setAdresse] = useState('');
    const [telephone, setTelephone] = useState('');
    const [notification, setNotification] = useState('');
    const notify = (message) => {
        setNotification(message);
        setTimeout(() => setNotification(''), 3000);
    };

    useEffect(() => {
        if (userData) {
            setEmail(userData.email || '');
            setPrenom(userData.firstName || '');
            setNom(userData.lastName || '');
            setPseudo(userData.username || '');
            setPays(userData.country || '');
            setCodePostal(userData.postalCode || '');
            setVille(userData.city || '');
            setAdresse(userData.postalAdress || '');
            setTelephone(userData.phoneNumber || '');
        }
    }, [userData]);
  const findCountryCode = (countryName) => {
    return Object.keys(countries).find(code => countries[code] === countryName);
  };

  const handleSubmit = () => {
    
    const updatedData = {
      firstName: prenom || null, lastName: nom || null, username: pseudo || null, country: pays || null,
      postalCode: codePostal || null, city: ville || null, postalAdress: adresse || null, phoneNumber: telephone || null
    };
  
    const token = localStorage.getItem('authToken');

    axios.patch(`${API_URL}/users/${userData?.id}`, updatedData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/merge-patch+json'
      }
    })
    .then(response => {
      notify("Mise à jour réussie");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
    .catch(error => {
      console.error('Erreur lors de la mise à jour:', error);
    });
  }
  
  return (
    
    <StyledBox>    
      {notification && (
        <div className='notify green-notify'>
          {notification}
        </div>
      )}
      <StyledTypography variant="h6" gutterBottom>Mes informations personnelles</StyledTypography>
      <FormRow>
          <Label htmlFor="email">Adresse Email</Label>
          <StyledInput width="50%" id="email" placeholder='Adresse Email' type="text" value={email} onChange={e => setEmail(e.target.value)} required />
          <StyledButton>Modifier l’adresse mail</StyledButton>
      </FormRow>

      <div className={styles.divFlexInfo}>
        <FormRow>
            <Label htmlFor="prenom">Prénom</Label>
            <StyledInput width="100%" id="prenom" placeholder='Prénom' type="text" value={prenom} onChange={e => setPrenom(e.target.value)} />
        </FormRow>

        <FormRow>
            <Label htmlFor="nom">Nom</Label>
            <StyledInput width="100%" id="nom" placeholder='Nom' type="text" value={nom} onChange={e => setNom(e.target.value)} />
        </FormRow>

        <FormRow>
            <Label htmlFor="pseudo">Pseudo</Label>
            <StyledInput width="100%" id="pseudo" placeholder='Pseudo' type="text" value={pseudo} onChange={e => setPseudo(e.target.value)} />
        </FormRow>
      </div>

      <div className={styles.divFlexInfo}>
          <FormRow>
              <Label htmlFor="pays">Pays</Label>
              <CountrySelector data={findCountryCode(userData?.country)} setPays={setPays} />
          </FormRow>
          <FormRow>
              <Label htmlFor="codePostal">Code Postal</Label>
              <StyledInput width="100%" id="codePostal" placeholder='Code Postal' type="text" value={codePostal} onChange={e => setCodePostal(e.target.value)} />
          </FormRow>
          <FormRow>
              <Label htmlFor="ville">Ville</Label>
              <StyledInput width="100%" id="ville" placeholder='Ville' type="text" value={ville} onChange={e => setVille(e.target.value)} />
          </FormRow>
      </div>
      <FormRow margin='20px 0 0 0'>
          <Label htmlFor="adresse">Adresse Postal</Label>
          <StyledInput width="100%" id="adresse" placeholder='Adresse' type="text" value={adresse} onChange={e => setAdresse(e.target.value)} />
      </FormRow>
      <Password/>
      <FormRow margin='20px 0 0 0' display='flex'>
          <div className={styles.divPhone}>
            <Label htmlFor="telephone">Numéro de Téléphone</Label>
            <StyledInput width="50%" id="telephone" placeholder='Numéro de Téléphone' type="text" value={telephone} onChange={e => setTelephone(e.target.value)} />
          </div>
          <div className={styles.divFinalBtn}>
            <StyledButtonFinal onClick={handleSubmit}>Mettre à jour mes informations</StyledButtonFinal>
          </div>
      </FormRow>
    </StyledBox>
  );
}

const StyledBox = styled(Box)({
  height: '100%', display: 'flex', flexDirection: 'column', gap: 2,
  boxShadow: '4px 4px 4px 0 rgba(0, 0, 0, 0.25)', backgroundColor:'#FFFFFF',
  borderRadius:20, padding: 30, paddingLeft:50
});
const StyledTypography = styled(Typography)({
  fontSize: '30px', fontWeight:'900', marginBottom:'30px'
});
const StyledInput = styled('input')(({ width }) => ({
  borderRadius: '10px', padding: '10px', width: width || '400px', height: '45px', fontFamily: 'Poppins, sans-serif', 
  fontWeight: '600', border: '2px solid black', transition: 'border-color 0.3s', fontSize: '15px',
  '&:focus': {
    outline: 'none', borderColor: '#F7CB46', backgroundColor: '#fff4d4',
  },
  '@media (max-width: 480px)': {
    width:'100%'
  },
}));

const StyledButton = styled('button')({
  width: '225px', height: '36px', fontSize: '15px', fontWeight: '600', borderRadius: '15px', 
  boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)', backgroundColor: '#F7CB46', marginLeft:'15px',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  '&:hover': {
      backgroundColor: '#FF7396',

  },
  '&:active': {
      boxShadow: '0px 2px 0px rgba(0, 0, 0, 1)',
      transform: 'translateY(2px)'
  },
  '@media (max-width: 480px)': {
    margin: 0,
    marginTop:'2vw',
    height: '50px',
    width:'100%'
  },
});
const StyledButtonFinal = styled(StyledButton)({
  width:'250px', height:'50px'
});

const Label = styled('label')({
  marginBottom: '5px', display: 'block', color: '#333',
  '@media (max-width: 480px)': {
    width:'100%',
  },
});

const FormRow = styled('div')(({ width, margin, display}) => ({
  display: display || 'block', flexDirection: 'row', width:width || "100%", margin: margin || 'none',
  '@media (max-width: 480px)': {
    flexDirection:'column'
  },
}));
export default RightInfos;

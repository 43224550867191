import React, {useState, useEffect} from 'react';
import { Grid, Box, Avatar, Typography } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';
import { API_URL } from '../../config';

function Post() {
    const [user, setUser] = useState(null);
    useEffect(() => {
      const authToken = localStorage.getItem('authToken');
      if (authToken) {
        const decoded = jwtDecode(authToken);
        const userId = decoded['id'];
        const config = {
          headers: {
            'Content-Type': 'application/ld+json',
            'Authorization': `Bearer ${authToken}`
          }
        };
  
        axios.get(`${API_URL}/users/${userId}`, config)
          .then(response => setUser(response.data))
          .catch(error => console.error('Error fetching user data:', error));
        

      }
    }, []);
  return (
    <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }}>
        <Grid container spacing={4} padding={5}>
          <Grid item xs={12} sx={{display: 'flex', width:'100%', alignItems:'center', gap: 8}}>
            <Box sx={{ display: 'flex', width:'20%', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', gap: 2, boxShadow: '4px 4px 4px 0 rgba(0, 0, 0, 0.25)', backgroundColor: '#F7F7F7', borderRadius: 5, padding: 4 }}>
                <Avatar sx={{ width: 70, height: 70 }} >
                    {!user?.avatarUrl && <AccountCircle sx={{ width: 70, height: 70 }} />}
                </Avatar>
                <Typography variant="h6" gutterBottom style={{ fontSize: '20px', fontWeight: '600', textAlign:'center', margin:0 }}>
                    {user ? `${user.firstName} ${user.lastName}` : 'Chargement...'}
                </Typography>
            </Box>
            <Box
            sx={{
                display: 'flex',
                height: '100%',
                justifyContent:'flex-end',
                flexDirection:'column',
                width:'80%',
            }}
            >
                <Typography variant="h6" gutterBottom style={{ fontSize: '30px', fontWeight: '600', marginBottom:20 }}>
                    Sujet: Bonjour
                    </Typography>
                <Box
                sx={{
                    display: 'flex',
                    height: 100,
                    width:'100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 2,
                    boxShadow: '4px 4px 4px 0 rgba(0, 0, 0, 0.25)',
                    backgroundColor: '#F7F7F7',
                    borderRadius: 5,
                    padding: 4,
                }}
                >
                <div style={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
                    <input
                    type="text"
                    placeholder="Rechercher..."
                    style={{
                        flex: 1,
                        height: '55px',
                        border: '2px solid black',
                        borderRadius: '10px 0 0 10px',
                        padding: '0 10px 0 15px',
                        fontSize: 16,
                    }}
                    />
                    <div
                    style={{
                        width: '55px',
                        height: '55px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        border: '2px solid black',
                        borderRadius: '0 10px 10px 0',
                        backgroundColor: '#F7CB46',
                    }}
                    >
                    <SearchIcon style={{ fontSize: 24, color: 'black' }} />
                    </div>
                </div>
                </Box>
            </Box>
          </Grid>
          <Grid item xs={12} >

            <Box sx={{ display: 'flex', height: 'auto', alignItems: 'center', justifyContent: 'flex-start', gap: 2, boxShadow: '4px 4px 4px 0 rgba(0, 0, 0, 0.25)', backgroundColor: '#F7F7F7', marginBottom: 5, borderRadius: 5, padding: 4 }}>
                <Box sx={{ display: 'flex', width:'50%', gap:4, border:'2px solid black', borderRadius:'15px', padding:'15px 25px 15px 25px'}}>
                    <Box sx={{display: 'flex', justifyContent:'center', alignItems:'center'}}>
                        <Avatar sx={{ width: 70, height: 70 }} >
                            {<AccountCircle sx={{ width: 70, height: 70 }} />}
                        </Avatar>
                    </Box>
                    <Box>
                        <Typography variant="h6" gutterBottom style={{ fontSize: '24px', fontWeight: '600' }}>
                        Nom Prénom
                        </Typography>
                        <Typography variant="p" gutterBottom style={{ fontSize: '16px', fontWeight: '300', width:'100%' }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud 
                        </Typography>
                    </Box>
                </Box>

            </Box>
          </Grid>
        </Grid>
    </Box>
  );
}

export default Post;

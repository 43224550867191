import React, { useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

const CarouselComponent = ({ children, itemsToShow, width }) => {
  const [activeStep, setActiveStep] = useState(0);
  const childrenArray = React.Children.toArray(children);
  const maxSteps = Math.ceil(childrenArray.length / itemsToShow);
  const goToSlide = (step) => {
    setActiveStep(step);
  };
  const handleNext = () => {
    setActiveStep(prevActiveStep => Math.min(prevActiveStep + 1, maxSteps - 1));
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => Math.max(prevActiveStep - 1, 0));
  };
  return (
    <BoxCarouselContainer>
      <BoxArrowContainer>
        <ArrowSlideRight onClick={handleBack} disabled={activeStep === 0} aria-label="previous slide">
          <CustomArrowFA icon={faPlay} rotation={180}/> 
        </ArrowSlideRight>

        <BoxParent>
          <BoxChildren>
            {childrenArray.slice(activeStep * itemsToShow, (activeStep + 1) * itemsToShow).map((child, index) => (
              React.cloneElement(child, { key: index })
            ))}
          </BoxChildren>
        </BoxParent>

        <ArrowSlideLeft onClick={handleNext} disabled={activeStep >= maxSteps - 1} aria-label="next slide">
          <CustomArrowFA icon={faPlay} rotation={0}/> 
        </ArrowSlideLeft>
        
      </BoxArrowContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px 0' }}>
        {[...Array(maxSteps)].map((_, index) => (
          <PaginationDot key={index} active={activeStep === index} onClick={() => {goToSlide(index);}}/>
        ))}
      </Box>
    </BoxCarouselContainer>
    
  );
};



const BoxCarouselContainer = styled(Box)({
  width:'70%',
  zIndex:20,
  '@media (max-width: 480px)': {
    width: '90%',
    height: '70%'
  },
});

const BoxArrowContainer = styled(Box)({
  display: 'flex', 
  alignItems: 'center', 
  justifyContent:'space-between', 
  overflow: 'hidden', 
  width:'100%', 
  height:'30vh',
  '@media (max-width: 480px)': {

    height: '100%'
  },
});

const BoxParent = styled(Box)({
  flexGrow: 1, 
  display: 'flex', 
  overflow: 'hidden', 
  width:'100%', 
  justifyContent:'center', 
  alignItems:'center',
  flexWrap: 'wrap',
  '@media (max-width: 480px)': {
  
    margin:0
  },
 
});

const BoxChildren = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent:"space-between",
  width: '100%',
  gap:'20px',
  marginBottom:'20px',
  flexWrap: 'wrap',
  '@media (max-width: 480px)': {
    marginBottom:'4px',
    gap:'5vw',

    justifyContent:"space-evenly",
  },
});

const ArrowSlide = styled('button')({
  display:'flex',
  alignItems:'center',
  width:'auto',
  textAlign:'center',
  padding: '0.5% 1% 0.5% 0.8%',
  height: 'auto',
  textTransform: 'none',
  border: '2px solid black',
  fontWeight: '700',
  borderRadius: '10px',
  boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)',
  fontSize: '15px',
  backgroundColor: 'white',
  cursor:'pointer',
  "&:hover": {
    backgroundColor:'#FF7396',
  },
  '&:active': {
    boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.5)', 
    transform: 'translateY(2px)',
  },
});
const ArrowSlideLeft = styled(ArrowSlide)({
  marginLeft:'50px',
  '@media (max-width: 480px)': {
    marginLeft:'20px',
  },
});
const ArrowSlideRight = styled(ArrowSlide)({
  marginRight:'50px',
  '@media (max-width: 480px)': {
    marginRight:'20px',
  },
});
const CustomArrowFA = styled(FontAwesomeIcon)({
  color:'black',
  fontSize:'30px'
});
const PaginationDot = styled('div')(({ active }) => ({
  height: '15px',
  width: '15px',
  backgroundColor: active ? '#FF7396' : 'white',
  display: 'inline-block',
  border: '2px solid black',
  borderRadius: '50%',
  boxShadow: '0px 4px 0px rgba(0, 0, 0, 1)',
  margin: '0 5px',
  cursor:'pointer',
  '&:hover': {
    backgroundColor: '#FF7396',
    cursor: 'pointer',
  },

}));
export default CarouselComponent;
